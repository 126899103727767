import { PrimitiveType } from 'intl-messageformat'
import React, { useCallback } from 'react'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'

import { REACT_APP_CM_STANDALONE } from '@/constants/moduleMode'
import defaultTranslation from '@/internalization/defaultTranslations/en.json'

const checkIfValidForTranslate = (string: string): boolean => string.indexOf('translate#') === 0

const turnIntoId = (string: string): string => string.replace('translate#', '')

export const useTranslate = (): ((
  string: string,
  options?: Record<string, PrimitiveType | JSX.Element>,
) => string) => {
  const intl = useIntl()
  const messages: { [key: string]: string } = defaultTranslation

  return useCallback(
    (string: string, options): string =>
      checkIfValidForTranslate(string)
        ? intl.formatMessage(
            {
              id: turnIntoId(string),
              defaultMessage:
                REACT_APP_CM_STANDALONE === 'true' ? undefined : messages[turnIntoId(string)],
            },
            options as Record<string, PrimitiveType>,
          )
        : string,
    [intl, messages],
  )
}

export const translate = (string: string, values?: any): React.ReactElement | string => {
  const messages: { [key: string]: string } = defaultTranslation

  return checkIfValidForTranslate(string) ? (
    <FormattedMessage
      id={turnIntoId(string)}
      defaultMessage={REACT_APP_CM_STANDALONE === 'true' ? undefined : messages[turnIntoId(string)]}
      values={values}
    />
  ) : (
    string
  )
}

export const formatNumber = (number: string | number = 0): React.ReactElement | string => {
  return <FormattedNumber value={+number} />
}

export const useFormatNumber = (): {
  formatNumber: (
    string?: string | number,
    isInteger?: boolean,
    minimumFractionDigits?: number,
  ) => string
  reverseFormatNumber: (string: string | number) => number
  formatWithDecimal: (value: string) => string
} => {
  const intl = useIntl()

  const reverseFormatNumber = (string: string | number): number => {
    const group = intl.formatNumber(11111).replace(/1/g, '')
    const decimal = intl.formatNumber(1.1).replace(/1/g, '')

    const reversedVal = +String(string)
      .replace(new RegExp(`\\${group}`, 'g'), '')
      .replace(new RegExp(`\\${decimal}`, 'g'), '.')
    return reversedVal
  }

  const formatNumber = (
    number?: string | number,
    isInteger = false,
    minimumFractionDigits = 0,
  ): string => {
    if (!number && number !== 0) return ''

    const string = number + ''
    const lastSymbol = string.slice(-1)
    const decimal = intl.formatNumber(1.1).replace(/1/g, '')
    const reversedNumber = reverseFormatNumber(string)

    const formattedNumber = intl.formatNumber(reversedNumber, {
      minimumFractionDigits,
      maximumFractionDigits: 16,
    })

    return lastSymbol === decimal && !isInteger ? formattedNumber + decimal : formattedNumber
  }

  const formatWithDecimal = (value: string): string => {
    const decimal = intl.formatNumber(1.1).replace(/1/g, '')

    if (value[0] === decimal && value.length === 1) {
      return `0${decimal}`
    }

    if (value.split(decimal).length > 2) {
      const secondDecimalPosition = value.indexOf(decimal, value.indexOf(decimal) + 1)

      return value.slice(0, secondDecimalPosition) + value.slice(secondDecimalPosition + 1)
    }

    return value
  }

  return {
    formatNumber,
    reverseFormatNumber,
    formatWithDecimal,
  }
}
