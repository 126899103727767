import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import moment from 'moment'
import qs from 'qs'

import { store } from '@/App'
import {
  APIConfiguration,
  AppNavigationRoutes,
  INITIAL_LOCALE,
  ISOLanguagesCodes,
  LocalStorageItems,
} from '@/constants'
import { CommonActions } from '@/constants/atmeye/actions/actionsCommon'
import { WINDOWS_TYPES } from '@/constants/atmeye/tables/windowTypes'
import { history } from '@/Router'
import { signOut } from '@/store/auth/actions'
import { RequestHTTPServiceTypes } from '@/types/common/services'
import { PopUpService } from '@/utils/services/popUpService'

export const LOG_LOGIN_URL = '/account/logLogin'
export const LOGIN_USM_URL = '/auth'
const DELAY_TO_SHOW_POPUP = 10000

const getFormData = (object: Record<string, any>): FormData => {
  const formData: FormData = new FormData()

  Object.keys(object).forEach(key => {
    if (Array.isArray(object[key])) {
      object[key].forEach((i: string | Blob) => formData.append(key, i))
    } else {
      formData.append(key, object[key])
    }
  })

  return formData
}

const popupTimeouts = new Map()
const timeZoneOffset = `UTC${moment().format('Z')}`
class RequestService implements RequestHTTPServiceTypes {
  private service: AxiosInstance
  constructor(baseURL: string | undefined = APIConfiguration.API_BASE_URL) {
    const service = axios.create({
      baseURL,
    })

    service.interceptors.request.use(config => {
      const token = sessionStorage.getItem(LocalStorageItems.AccessToken) || ''
      const userId = sessionStorage.getItem(LocalStorageItems.UserID) || ''
      const language = sessionStorage.getItem(LocalStorageItems.Locale) || INITIAL_LOCALE

      config.headers['Content-type'] = 'application/json; charset=UTF-8'
      // temp solution
      if (config.url !== LOGIN_USM_URL) {
        config.headers.Authorization = `Bearer ${token}`
        config.headers['User-Id'] = userId
        config.headers['User-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone // deprecated, should be removed
        config.headers['User-Timezone-Offset'] = timeZoneOffset // should be used instead of User-Timezone
      }

      if (!config.headers['accept-language']) {
        config.headers['accept-language'] = ISOLanguagesCodes[language]
      }

      if (config.data instanceof FormData) {
        Object.assign(config.headers, { 'Content-Type': 'application/x-www-form-urlencoded' })
      }

      return config
    })

    const currentExecutingRequests: any = {}

    service.interceptors.request.use(
      req => {
        const originalRequest = req
        const cancelUniqId = (originalRequest.cancelToken as unknown) as string

        if (Object.hasOwnProperty.call(currentExecutingRequests, cancelUniqId)) {
          const source = currentExecutingRequests[cancelUniqId]
          delete currentExecutingRequests[cancelUniqId]
          source.cancel()
        }

        if (cancelUniqId) {
          const { CancelToken } = axios
          const source = CancelToken.source()
          originalRequest.cancelToken = source.token
          currentExecutingRequests[cancelUniqId] = source
        }

        return originalRequest
      },
      err => {
        return Promise.reject(err)
      },
    )

    service.interceptors.response.use(
      response => {
        for (const key of Object.keys(currentExecutingRequests)) {
          if (currentExecutingRequests[key].token === response.config.cancelToken) {
            delete currentExecutingRequests[key]
            break
          }
        }
        return response
      },
      error => {
        const { response } = error

        if (axios.isCancel(error)) {
          return new Promise(() => {
            //
          })
        }

        for (const key of Object.keys(currentExecutingRequests)) {
          if (currentExecutingRequests[key].token === response.config.cancelToken) {
            delete currentExecutingRequests[key]
            break
          }
        }

        return Promise.reject(error)
      },
    )

    // service.interceptors.request.use(config => {
    //   const popupTimeout = window.setTimeout(() => {
    //     PopUpService.showGlobalPopUp('translate#title.data.are.loading', 'info')
    //   }, DELAY_TO_SHOW_POPUP)
    //
    //   popupTimeouts.set(config, popupTimeout)
    //
    //   return config
    // })

    service.interceptors.response.use(response => {
      const popupTimeout = popupTimeouts.get(response.config)
      popupTimeouts.delete(response.config)

      clearTimeout(popupTimeout)

      return response
    })

    service.interceptors.response.use(this.handleSuccess, this.handleError)

    this.service = service
  }

  private handleSuccess = (response: AxiosResponse): AxiosResponse => {
    return response
  }

  private handleError = (error: AxiosError): void | any => {
    if (axios.isCancel(error)) {
      throw new Error(error.message)
    }

    if (error.response) {
      switch (error.response.status) {
        // Bad Request
        case 400:
          PopUpService.showGlobalPopUp(`translate#response.error.badRequest`, 'error')
          // throw new Error(error.response.data ?? error.response.data)
          if (error.response.data.errorMessage) return Promise.reject(error.response.data)
          return Promise.reject(error.response.data.message)
        // for correct displaying error message should return Promise.reject in api method and use useErrorMessage hook
        // Unauthorized
        case 401:
          // it will be changed when server of auth will be ready
          if (error.response?.data.message === 'Change password') {
            store.dispatch(signOut())
            history.push(AppNavigationRoutes.ChangePassword)
            PopUpService.showGlobalPopUp(`translate#response.error.unauthorized`, 'error')
            throw new Error(error.response.data.errorMessage ?? error.response.data.message)
            // throw new Error(error.response.data.message)
          }
          store.dispatch(signOut())
          history.push(AppNavigationRoutes.SignInPage)
          PopUpService.showGlobalPopUp(`translate#response.error.unauthorized`, 'error')
          throw error.response.data.errorMessage ?? error.response.data.message
        // throw new Error(error.response.data.message)

        // Forbidden
        case 403:
          store.dispatch(signOut())
          history.push(AppNavigationRoutes.SignInPage)
          PopUpService.showGlobalPopUp(`translate#response.error.forbidden`, 'error')
          throw new Error(error.response.data.errorMessage ?? error.response.data.message)
        // throw new Error(error.response.data.message)
        // Not Found
        case 404:
          PopUpService.showGlobalPopUp('translate#response.error.notFound', 'error')
          throw new Error(error.response.data.errorMessage ?? error.response.data.message)
        // throw new Error(error.response.data.message)
        // Validations errors
        case 406: {
          const responseData =
            error.response.data instanceof ArrayBuffer
              ? JSON.parse(
                  String.fromCharCode.apply(null, new Uint8Array(error.response.data) as any),
                )
              : error.response.data

          PopUpService.showGlobalPopUp(
            `translate#${responseData.errorMessage}`,
            'warning',
            responseData.messageParameters,
          )
          throw new Error(responseData.errorMessage ?? responseData.message)
        }
        case 418: {
          const { errorMessage, messageParameters } = error.response.data

          store.dispatch({
            type: CommonActions.SetAckWarning,
            payload: { errorMessage, messageParameters },
          })
          if (error.response.data.errorMessage) return Promise.reject(error.response.data)
          throw new Error(error.response.data.message)
        }
        case 422:
          // @todo add validation errors showing in popup
          !error.response.data.errorMessage.includes('atmeye.transactions.data.limit') &&
            PopUpService.showGlobalPopUp(`translate#response.error.validationsErrors`, 'error')
          throw new Error(error.response.data.errorMessage ?? error.response.data.message)
        // throw new Error(error.response.data.message)

        // Internal Server Error
        case 500:
          PopUpService.showGlobalPopUp(`translate#response.error.internalServerError`, 'error')
          throw new Error(error.response.data.errorMessage ?? error.response.data.message)
        // throw new Error(error.response.data.message)
        // Bad Gateway
        case 502:
          PopUpService.showGlobalPopUp(`translate#response.error.badGateway`, 'error')
          throw new Error(error.response.data.errorMessage ?? error.response.data.message)
        // throw new Error(error.response.data.message)
        // Service Unavailable
        case 503:
          PopUpService.showGlobalPopUp(`translate#response.error.serviceUnavailable`, 'error')
          throw new Error(error.response.data.errorMessage ?? error.response.data.message)
        // throw new Error(error.response.data.message)
        // Gateway Timeout
        case 504:
          PopUpService.showGlobalPopUp(`translate#response.error.badRequest`, 'error')
          throw new Error(error.response.data.errorMessage ?? error.response.data.message)
        // throw new Error(error.response.data.message)

        case 507:
          throw new Error(error.response.data.errorMessage ?? error.response.data.message)
        default:
      }
    } else {
      history.push(AppNavigationRoutes.SignInPage)
      throw new Error(error.message)
    }
  }

  public get(
    path: string,
    params?: unknown,
    additionalParams?: any,
    withHeaders?: boolean,
  ): Promise<AxiosResponse['data']> {
    return this.service
      .get(path, {
        params,
        ...additionalParams,
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      })
      .then(response => {
        return withHeaders ? response : response.data
      })
  }

  public patch = (path: string, data: unknown): Promise<AxiosResponse['data']> => {
    return this.service.patch(path, data).then(response => response.data)
  }

  public post = (
    path: string,
    data: unknown,
    withHeaders?: boolean,
    config?: any,
  ): Promise<AxiosResponse['data']> => {
    return this.service.post(path, data, config).then(response => {
      return withHeaders
        ? { data: response.data, headers: response.headers, status: response.status }
        : response.data
    })
  }

  public postFormData = (
    path: string,
    data: Record<string, any>,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse['data']> => {
    return this.service.post(path, getFormData(data), config).then(response => response.data)
  }

  public put = (path: string, data: unknown): Promise<AxiosResponse['data']> => {
    return this.service.put(path, data).then(response => response?.data)
  }

  public delete = (
    path: string,
    withHeaders?: boolean,
    config?: any,
  ): Promise<AxiosResponse['data']> => {
    return this.service
      .delete(path, config)
      .then(response => (withHeaders ? response : response.data))
  }
}

export const requestService = new RequestService()

export const getRequestService = (
  baseURL: string | undefined = APIConfiguration.API_BASE_URL,
): RequestService => new RequestService(baseURL)
