export enum CMActions {
  AlternateModal = '@/ALTERNATE_COLUMN_MODAL',
  GetCashPointsForMainRequest = '@/GET_CASHPOINT_FOR_MAIN_REQUEST',
  GetCashPointsForMainResponse = '@/GET_CASHPOINT_FOR_MAIN_RESPONSE',
  GetCashPointsForMainResponseFail = '@/GET_CASH_POINT_FOR_MAIN_RESPONSE_FAIL',
  GetCashPointDetailsRequest = '@/GET_CASH_POINT_DETAILS_REQUEST',
  GetCashPointDetailsResponse = '@/GET_CASH_POINT_DETAILS_RESPONSE',
  GetCashPointDetailsResponseFail = '@/GET_CASH_POINT_DETAILS_RESPONSE_FAIL',
  ChangePasswordRequest = '@/CM_USR_CHANGE_PASSWORD_REQUEST',
  ChangePasswordResponse = '@/CM_USR_CHANGE_PASSWORD_RESPONSE',
  ChangePasswordResponseFail = '@/CM_USR_CHANGE_PASSWORD_RESPONSE_FAIL',
  SetChangePasswordModalOpen = '@/CM_SET_CHANGE_PASSWORD_MODAL_OPEN',
  SetAboutModalOpen = '@/CM_SET_ABOUT_MODAL_OPEN',
  GetFilterNodesRequest = '@/GET_FILTER_NODES_REQUEST_CM',
  GetFilterNodesResponse = '@/GET_FILTER_NODES_RESPONSE_CM',
  GetFilterNodesResponseFail = '@/GET_FILTER_NODES_RESPONSE_FAIL_CM',

  GetCashPointCassettesRequest = '@/GET_CASH_POINT_CASSETTES_REQUEST',
  GetCashPointCassettesResponse = '@/GET_CASH_POINT_CASSETTES_RESPONSE',
  GetCashPointCassettesFail = '@/GET_CASH_POINT_CASSETTES_FAIL',

  GetMonCassettesChartsRequest = '@/GET_MON_CASSETTES_CHARTS_REQUEST',
  GetMonCassettesChartsResponse = '@/GET_MON_CASSETTES_CHARTS_RESPONSE',
  GetMonCassettesChartsFail = '@/GET_MON_CASSETTES_CHARTS_FAIL',

  GetMonCINChartsRequest = '@/GET_MON_CIN_CHARTS_REQUEST',
  GetMonCINChartsResponse = '@/GET_MON_CIN_CHARTS_RESPONSE',
  GetMonCINChartsFail = '@/GET_MON_CIN_CHARTS_FAIL',

  GetCurrenciesRequest = '@/GET_CASH_POINT_CURRENCIES_REQUEST',
  GetCurrenciesResponse = '@/GET_CASH_POINT_CURRENCIES_RESPONSE',
  GetCurrenciesResponseFail = '@/GET_CASH_POINT_CURRENCIES_RESPONSE_FAIL',
  GetFilterCurrenciesRequest = '@/GET_CASH_POINT_FILTER_CURRENCIES_REQUEST',
  GetFilterCurrenciesResponse = '@/GET_CASH_POINT_FILTER_CURRENCIES_RESPONSE',
  GetFilterCurrenciesResponseFail = '@/GET_CASH_POINT_FILTER_CURRENCIES_RESPONSE_FAIL',

  CheckIsLogin = '@/CHECK_IS_LOGIN_CM',
  SignInRequest = '@/CM_SIGN_IN_REQUEST',
  SignInResponse = '@/CM_SIGN_IN_RESPONSE',
  SignInResponseFail = '@/CM_SIGN_IN_RESPONSE_FAIL',
  SignOut = '@/CM_SIGN_OUT',
  SetUserCM = '@/CM_SET_USER',
  SetFilterType = '@/SET_FILTER_TYPE',
  SetMonCashPointDetailsCurrencyFilter = '@/CM_SET_MONITORING_CP_DETAILS_CURRENCY_FILTER',
  SetMonCashPointDetailsCassettesFilter = '@/CM_SET_MONITORING_CP_DETAILS_CASSETTES_FILTER',
  SetMonCashPointDetailsFilter = '@/CM_SET_MONITORING_CP_DETAILS_FILTER',
  SetMonCashPointDetailsCinFilter = '@/CM_SET_MONITORING_CP_DETAILS_CIN_FILTER',

  SetSelectedDate = '@/SET_SELECTED_DATE',
  MonSetSelectedCashPoint = '@/CM_MON_SET_SELECTED_CASH_POINT',
  SetCPDetailsTabNumber = '@/SET_CP_DETAILS_TAB_NUMBER',
  ToggleColumns = '@/TOGGLE_COLUMN',
  SetAllCurenciesValueAction = '@/TOGGLE_SHOW_ALL_CURRENCIES',
  SetFiltersModalOpen = '@/CM_SET_FILTERS_MODAL_OPEN',
  SetDocumentModalOpen = '@/CM_SET_DOCUMENT_MODAL_OPEN',
  SetMonCashPointsPagingRequest = '@/CM_SET_MON_CASH_POINTS_PAGING_REQUEST',
  SetMonCashPointsPagingResponse = '@/CM_SET_MON_CASH_POINTS_PAGING_RESPONSE',
  SetPlnCashPointsPagingRequest = '@/CM_SET_PLN_CASH_POINTS_PAGING_REQUEST',
  SetPlnCashPointsPagingResponse = '@/CM_SET_PLN_CASH_POINTS_PAGING_RESPONSE',
  SetGlobalFilter = '@/CM_SET_GLOBAL_FILTER',
  SetObjectTreeFilter = '@/CM_SET_OBJECT_TREE_FILTER',
  SetMonitoringFilter = '@/CM_SET_MONITORING_FILTER',
  SetCashPointsFilter = '@/CM_SET_CASH_POINTS_FILTER',
  GetMonitoringReportsRequest = '@/CM_GET_MONITORING_REPORTS_REQUEST',
  GetMonitoringReportsResponse = '@/CM_GET_MONITORING_REPORTS_RESPONSE',
  GetMonitoringReportsResponseFail = '@/CM_GET_MONITORING_REPORTS_RESPONSE_FAIL',
  GetMonitoringReportRequest = '@/CM_GET_MONITORING_REPORT_REQUEST',
  GetMonitoringReportResponse = '@/CM_GET_MONITORING_REPORT_RESPONSE',
  GetMonitoringReportResponseFail = '@/CM_GET_MONITORING_REPORT_RESPONSE_FAIL',
  GetMonitoringSettingsRequest = '@/CM_GET_MONITORING_SETTINGS',
  GetPlnReportsRequest = '@/CM_GET_PLN_REPORTS_REQUEST',
  GetPlnReportsResponse = '@/CM_GET_PLN_REPORTS_RESPONSE',
  GetPlnReportsResponseFail = '@/CM_GET_PLN_REPORTS_RESPONSE_FAIL',
  GetPlnReportRequest = '@/CM_GET_PLN_REPORT_REQUEST',
  GetPlnReportResponse = '@/CM_GET_PLN_REPORT_RESPONSE',
  GetPlnReportResponseFail = '@/CM_GET_PLN_REPORT_RESPONSE_FAIL',
  RefreshMonitoring = '@/CM_REFRESH_MONITORING',
  MonRefreshCashPointRequest = '@/CM_MON_REFRESH_CASH_POINT_REQUEST',
  MonRefreshCashPointResponse = '@/CM_MON_REFRESH_CASH_POINT_RESPONSE',
  MonHandleCassettesUpperLimit = '@/CM_MON_HANDLE_CASSETTES_UPPER_LIMIT',
  MonApplySelectedList = '@/CM_MON_APPLY_SELECTED_LIST',
  MonRemoveSelectedList = '@/CM_MON_REMOVE_SELECTED_LIST',
  MonToggleCashPoint = '@/CM_MON_TOGGLE_CASH_POINT',
  MonClearCheckedCashPoints = '@/CM_MON_CLEAR_CHECKED_CASH_POINTS',
  MonCheckAllCashPoints = '@/CM_MON_CHECK_ALL_CASH_POINTS',
  MonSetPlanStatus = '@/CM_MON_SET_PLAN_STATUS',
  MonResetCashPointsPage = '@/CM_MON_RESET_CASH_POINTS_PAGE',
  MonSortCashPointsTable = '@/CM_MON_SORT_CASH_POINTS_TABLE',
  MonAddFilterIds = '@/CM_MON_ADD_FILTER_IDS',
  MonRemoveFilterIds = '@/CM_MON_REMOVE_FILTER_IDS',
  MonHandleKeyPress = '@/CM_MON_HANDLE_KEY_PRESS',
  MonSetDetailsFilterModalOpen = '@/CM_MON_SET_DETAILS_FILTER_MODAL_OPEN',

  PlnResetCashPointsPage = '@/CM_PLN_RESET_CASH_POINTS_PAGE',
  GetCultures = '@/CM_GET_CULTURES',
  SetServerCulture = '@/CM_SET_SERVER_CULTURE',
  PlnGetCashPointsRequest = '@/CM_PLN_GET_CASH_POINTS_REQUEST',
  PlnGetCashPointsResponse = '@/CM_PLN_GET_CASH_POINTS_RESPONSE',
  PlnGetCashPointsResponseFail = '@/CM_PLN_GET_CASH_POINTS_RESPONSE_FAIL',
  PlnSetFiltersModalOpen = '@/CM_PLN_SET_FILTERS_MODAL_OPEN',
  PlnSetDocumentModalOpen = '@/CM_PLN_SET_DOCUMENT_MODAL_OPEN',
  PlnSetSettingsModalOpen = '@/CM_PLN_SET_SETTINGS_MODAL_OPEN',
  PlnSetModifyModalOpen = '@/CM_PLN_SET_MODIFY_MODAL_OPEN',
  PlnSetCalculateModalOpen = '@/CM_PLN_SET_CALCULATE_MODAL_OPEN',
  PlnSetLoadingModalOpen = '@/CM_PLN_SET_LOADING_MODAL_OPEN',
  PlnFilterSubmitRequest = '@/CM_PLN_FILTER_SUBMIT_REQUEST',
  PlnSetCashPointsFilter = '@/CM_PLN_SET_CASH_POINTS_FILTER',

  ComGetCompaniesRequest = '@/COM_GET_COMPANIES_REQUEST',
  ComGetCompaniesResponse = '@/COM_GET_COMPANIES_RESPONSE',

  ComSetIsCompanyModal = '@/COM_SET_IS_COMPANY_MODAL',

  ComSetNewCompanyRequest = '@/COM_SET_NEW_COMPANY_REQUEST',
  ComSetNewCompanyResponse = '@/COM_SET_NEW_COMPANY_RESPONSE',
  ComSetNewCompanyFail = '@/COM_SET_NEW_COMPANY_FAIL',

  ComGetUsersRequest = '@/CM_COM_GET_USERS_REQUEST',
  ComGetUsersResponse = '@/CM_COM_GET_USERS_RESPONSE',
  ComGetUsersResponseFail = '@/CM_COM_GET_USERS_RESPONSE_FAIL',

  ComGetUsersOptionsRequest = '@/CM_COM_GET_USERS_OPTIONS_REQUEST',
  ComGetUsersOptionsResponse = '@/CM_COM_GET_USERS_OPTIONS_RESPONSE',

  ComSetError = '@/CM_COM_SET_ERROR',

  ComGetSessionDocumentsReportsRequest = '@/CM_COM_GET_SESSION_DOCUMENTS_REPORTS_REQUEST',
  ComGetSessionDocumentsReportsResponse = '@/CM_COM_GET_SESSION_DOCUMENTS_REPORTS_RESPONSE',
  ComGetSessionDocumentsReportsFail = '@/CM_COM_GET_SESSION_DOCUMENTS_REPORTS_FAIL',
  ComGetSessionDocumentsReportRequest = '@/CM_COM_GET_SESSION_DOCUMENTS_REPORT_REQUEST',
  ComGetSessionDocumentsReportResponse = '@/CM_COM_GET_SESSION_DOCUMENTS_REPORT_RESPONSE',
  ComGetSessionDocumentsReportFail = '@/CM_COM_GET_SESSION_DOCUMENTS_REPORT_FAIL',
  ComSetInitialReportsCM = '@/CM_COM_SET_INITIAL_REPORTS',

  ComGetFilterTIDCashPointsRequest = '@/CM_COM_GET_FILTER_TID_CASH_POINTS_REQUEST',
  ComGetFilterTIDCashPointsResponse = '@/CM_COM_GET_FILTER_TID_CASH_POINTS_RESPONSE',
  ComGetFilterTIDCashPointsFail = '@/CM_COM_GET_FILTER_TID_CASH_POINTS_FAIL',

  ComSetSelectedFilterTIDCashPoints = '@/CM_COM_SET_SELECTED_FILTER_TID_CASH_POINTS',

  ComGetBuildInfoRequestCM = '@/CM_COM_GET_BUILD_INFO_REQUEST',
  ComGetBuildInfoResponseCM = '@/CM_COM_GET_BUILD_INFO_RESPONSE',
  ComGetBuildInfoResponseFailCM = '@/CM_COM_GET_BUILD_INFO_RESPONSE_FAIL',

  ComGetCurrenciesAndDenominationsRequest = '@/CM_GET_COM_CURRENCIES_AND_DENOMINATIONS_REQUEST',
  ComGetCurrenciesAndDenominationsResponse = '@/CM_GET_COM_CURRENCIES_AND_DENOMINATIONS_RESPONSE',

  ComSetActErrorVisible = '@/CM_COM_SET_ACT_ERROR_VISIBLE',
  ComSetActErrorInitial = '@/CM_COM_SET_ACT_ERROR_INITIAL',

  ComSetBalancingDialogInitial = '@/CM_COM_SET_BALANCING_DIALOG_INITIAL',

  // Accounts -------------------------------------------------
  ComSetParsedCurrencies = '@/CM_COM_SET_PARSED_CURRENCIES',
  ComSetAccountsInitial = '@/CM_COM_SET_ACCOUNTS_INITIAL',
  ComUpdateAccountRow = '@/CM_COM_UPDATE_ACCOUNT_ROW',
  ComSetSelectedAccountRow = '@/CM_COM_SET_SELECTED_ACCOUNT_ROW',
  ComAddNewAccount = '@/CM_COM_ADD_NEW_ACCOUNT',
  ComRemoveAccountRow = '@/CM_COM_REMOVE_ACCOUNT_ROW',

  // Cassettes -------------------------------------------------
  ComGetCassetteCurrenciesRequest = '@/CM_COM_GET_CASSETTE_CURRENCIES_REQUEST',
  ComGetCassetteCurrenciesResponse = '@/CM_COM_GET_CASSETTE_CURRENCIES_RESPONSE',
  ComGetCassetteCurrenciesFail = '@/CM_COM_GET_CASSETTE_CURRENCIES_FAIL',

  ComGetCassetteTypesRequest = '@/CM_COM_GET_CASSETTE_TYPES_REQUEST',
  ComGetCassetteTypesResponse = '@/CM_COM_GET_CASSETTE_TYPES_RESPONSE',
  ComGetCassetteTypesFail = '@/CM_COM_GET_CASSETTE_TYPES_FAIL',

  ComGetAvailableCassetteNumbersRequest = '@/CM_COM_GET_AVAILABLE_CASSETTE_NUMBERS_REQUEST',
  ComGetAvailableCassetteNumbersResponse = '@/CM_COM_GET_AVAILABLE_CASSETTE_NUMBERS_RESPONSE',

  // Calc Methods ----------------------------------------------

  ComSetIsWorkingWithTeam = '@/CM_COM_SET_IS_WORKING_WITH_TEAM',

  ComSetCashSortersModal = '@/CM_COM_SET_CASH_SORTERS_MODAL',
  ComSetCloseCashSorterModal = '@/CM_COM_SET_CLOSE_CASH_SORTER_MODAL',
  ComSetSocketData = '@/CM_COM_SET_SOCKET_DATA',

  ComSetSorterStatus = '@/CM_COM_SET_SORTER_STATUS',

  ComGetIntegrationsRequest = '@/CM_COM_GET_INTEGRATIONS_REQUEST',
  ComGetIntegrationsResponse = '@/CM_COM_GET_INTEGRATIONS_RESPONSE',

  ComGetVaultsWithLocationRequest = '@/CM_COM_GET_VAULTS_WITH_LOCATION_REQUEST',
  ComGetVaultsWithLocationResponse = '@/CM_COM_GET_VAULTS_WITH_LOCATION_RESPONSE',

  ComGetTeamsRequest = '@/CM_COM_GET_TEAMS_REQUEST',
  ComGetTeamsResponse = '@/CM_COM_GET_TEAMS_RESPONSE',

  // Approve Items ----------------------------------------------
  ComOpenApproveItemsModal = '@/CM_COM_OPEN_APPROVE_ITEMS_MODAL',

  ComGetApproveItemsRequest = '@/CM_COM_GET_APPROVE_ITEMS_REQUEST',
  ComGetApproveItemsResponse = '@/CM_COM_GET_APPROVE_ITEMS_RESPONSE',
  ComGetApproveItemsFail = '@/CM_COM_GET_APPROVE_ITEMS_FAIL',

  ComUpdateLocalApproveItems = '@/CM_COM_UPDATE_LOCAL_APPROVE_ITEMS',

  ComSetApproveItemsRequest = '@/CM_COM_SET_APPROVE_ITEMS_REQUEST',
  ComSetApproveItemsResponse = '@/CM_COM_SET_APPROVE_ITEMS_RESPONSE',
  ComSetApproveItemsFail = '@/CM_COM_SET_APPROVE_ITEMS_FAIL',

  // Planning  ----------------------------------------------
  RefreshPlanning = '@/CM_REFRESH_PLANNING',
  PlnToggleCashPoint = '@/CM_PLN_TOGGLE_CASH_POINT',
  PlnCheckAllCashPoints = '@/CM_PLN_CHECK_ALL_CASH_POINTS',
  PlnClearAllCashPoints = '@/CM_PLN_CLEAR_ALL_CASH_POINTS',
  PlnGetConfigRequest = '@/CM_PLN_GET_CONFIG_REQUEST',
  PlnGetConfigResponse = '@/CM_PLN_GET_CONFIG_RESPONSE',
  PlnGetConfigResponseFail = '@/CM_PLN_GET_CONFIG_RESPONSE_FAIL',
  PlnSetSelectedCashPoint = '@/CM_PLN_SET_SELECTED_CASH_POINT',
  PlnCalculateRequest = '@/CM_PLN_CALCULATE_REQUEST',
  PlnAcceptRequest = '@/CM_PLN_ACCEPT_REQUEST',
  PlnSendToExecuteRequest = '@/CM_PLN_SEND_TO_EXECUTE_REQUEST',
  PlnCalculateExtRequest = '@/CM_PLN_CALCULATE_EXT_REQUEST',
  PlnCalculateExtResponse = '@/CM_PLN_CALCULATE_EXT_RESPONSE',
  PlnRefreshCashPointRequest = '@/CM_PLN_REFRESH_CASH_POINT_REQUEST',
  PlnRefreshCashPointResponse = '@/CM_PLN_REFRESH_CASH_POINT_RESPONSE',
  PlnRefreshCashPointResponseFail = '@/CM_PLN_REFRESH_CASH_POINT_RESPONSE_FAIL',
  PlnModifyRequest = '@/CM_PLN_MODIFY_REQUEST',
  SortPlnTable = '@/CM_PLN_SORT_TABLE',
  PlnSetCashPointPriority = '@/CM_PLN_SET_CASH_POINT_PRIORITY',

  PlnSetDataCountToUpdate = '@/CM_PLN_SET_DATA_COUNT_TO_UPDATE',

  PlnModifyConfigRequest = '@/CM_PLN_MODIFY_CONFIG_REQUEST',
  PlnSetModifyApplyDisabled = '@/CM_PLN_SET_MODIFY_APPLY_DISABLED',
  PlnGetModifyExpensesRequest = '@/CM_PLN_GET_MODIFY_EXPENSES_REQUEST',
  PlnGetModifyExpensesResponse = '@/CM_PLN_GET_MODIFY_EXPENSES_RESPONSE',
  PlnGetModifyExpensesResponseFail = '@/CM_PLN_GET_MODIFY_EXPENSES_RESPONSE_FAIL',
  PlnGetSettingsRequest = '@/CM_PLN_GET_SETTINGS_REQUEST',
  PlnGetSettingsResponse = '@/CM_PLN_GET_SETTINGS_RESPONSE',
  PlnGetSettingsResponseFail = '@/CM_PLN_GET_SETTINGS_RESPONSE',
  PlnGetDeclineNoteRequest = '@/CM_PLN_GET_DECLINE_NOTE_REQUEST',
  PlnGetDeclineNoteResponse = '@/CM_PLN_GET_DECLINE_NOTE_RESPONSE',
  PlnGetDeclineNoteResponseFail = '@/CM_PLN_GET_DECLINE_NOTE_RESPONSE_FAIL',
  PlnSetDeclineNoteModalOpen = '@/CM_PLN_SET_DECLINE_NOTE_MODAL_OPEN',
  PlnHandleKeyPress = '@/CM_PLN_HANDLE_KEY_PRESS',
  PlnSetModifyDisabledState = '@/CM_PLN_SET_MODIFY_DISABLED_STATE',

  // Orders
  OrdRefreshOrderRequest = '@/CM_ORD_REFRESH_ORDER_REQUEST',
  OrdRefreshOrderResponse = '@/CM_ORD_REFRESH_ORDER_RESPONSE',
  OrdRefreshOrderResponseFail = '@/CM_ORD_REFRESH_ORDER_RESPONSE_FAIL',
  OrdGetOrdersRequest = '@/CM_ORD_GET_ORDERS_REQUEST',
  OrdGetOrdersResponse = '@/CM_ORD_GET_ORDERS_RESPONSE',
  OrdGetOrdersResponseFail = '@/CM_ORD_GET_ORDERS_RESPONSE_FAIL',
  OrdSetLoadingModalOpen = '@/CM_ORD_SET_LOADING_MODAL_OPEN',
  OrdSetDeclineModalOpen = '@/CM_ORD_SET_DECLINE_MODAL_OPEN',
  OrdSetDocumentsModalOpen = '@/CM_ORD_SET_DOCUMENTS_MODAL_OPEN',
  OrdSetFiltersModalOpen = '@/CM_ORD_SET_FILTERS_MODAL_OPEN',
  OrdSetSettingsModalOpen = '@/CM_ORD_SET_SETTINGS_MODAL_OPEN',
  OrdFilterSubmitRequest = '@/CM_ORD_FILTER_SYBMIT_REQUEST',
  OrdSetOrdersFilter = '@/CM_ORD_SET_ORDERS_FILTER',
  OrdResetOrdersPage = '@/CM_ORD_RESET_ORDERS_PAGE',
  OrdGetReportsRequest = '@/CM_ORD_GET_REPORTS_REQUEST',
  OrdGetReportsResponse = '@/CM_ORD_GET_REPORTS_RESPONSE',
  OrdGetReportsResponseFail = '@/CM_ORD_GET_REPORTS_RESPONSE_FAIL',
  OrdGetReportRequest = '@/CM_ORD_GET_REPORT_REQUEST',
  OrdGetReportResponse = '@/CM_ORD_GET_REPORT_RESPONSE',
  OrdGetReportResponseFail = '@/CM_ORD_GET_REPORT_RESPONSE_FAIL',
  OrdSetSelectedOrder = '@/CM_ORD_SET_SELECTED_ORDER',
  OrdToggleOrder = '@/CM_ORD_TOGGLE_ORDER',
  OrdToggleAllOrders = '@/CM_ORD_TOGGLE_ALL_ORDERS',
  OrdCheckSelectedOrders = '@/CM_ORD_CHECK_SELECTED_ORDERS',
  SortOrdTable = '@/CM_ORD_SORT_TABLE',
  RefreshOrders = '@/CM_REFRESH_ORDERS',
  OrdApproveOrders = '@/CM_ORD_APPROVE_ORDER',
  OrdDeclineOrders = '@/CM_ORD_DECLINE_ORDER',
  OrdHandleKeyPress = '@/CM_ORD_HANDLE_KEY_PRESS',
  OrdGetDeclineNoteRequest = '@/CM_ORD_GET_DECLINE_NOTE_REQUEST',
  OrdGetDeclineNoteResponse = '@/CM_ORD_GET_DECLINE_NOTE_RESPONSE',
  OrdGetDeclineNoteResponseFail = '@/CM_ORD_GET_DECLINE_NOTE_RESPONSE_FAIL',
  OrdSendToExecuteOrders = '@/CM_ORD_SEND_TO_EXECUTE_ORDER',
  OrdApproveOrdersResponseFail = '@/CM_ORD_APPROVE_ORDER_FAIL',
  OrdDeclineOrdersResponseFail = '@/CM_ORD_DECLINE_ORDER_FAIL',
  OrdSendToExecuteOrdersResponseFail = '@/CM_ORD_SEND_TO_EXECUTE_ORDER_FAIL',
  OrdGetStagesDocRequest = '@/CM_ORD_GET_STAGES_DOC_REQUEST',
  OrdGetStagesDocResponse = '@/CM_ORD_GET_STAGES_DOC_RESPONSE',
  OrdSetSelectedSessionDoc = '@/CM_ORD_SET_SELECTED_SESSION_DOC',
  OrdGroupOrdersRequest = '@/CM_ORD_GROUP_ORDERS_REQUEST',
  OrdGroupOrdersResponseFail = '@/CM_ORD_GROUP_ORDERS_RESPONSE_FAIL',
  OrdSetOrdersPaging = '@/CM_SET_ORD_ORDERS_PAGING',

  OrdUngroupOrdersRequest = '@/CM_ORD_UNGROUP_ORDERS_REQUEST',
  OrdUngroupOrdersResponseFail = '@/CM_ORD_UNGROUP_ORDERS_RESPONSE_FAIL',
  OrdSetDeclineNoteModalOpen = '@/CM_ORD_SET_DECLINE_NOTE_MODAL_OPEN',
  OrdAssignCashierAndCollectorRequest = '@/CM_ORD_ASSIGN_CASHIER_AND_COLLECTOR_REQUEST',

  OrdOperationsRequest = '@/CM_ORD_OPERATIONS_REQUEST',
  OrdOperationsResponse = '@/CM_ORD_OPERATIONS_RESPONSE',
  OrdOperationsFail = '@/CM_ORD_OPERATIONS_FAIL',

  OrdGetCashPointDriversRequest = '@/CM_ORD_GET_CASH_POINT_DRIVERS_REQUEST',
  OrdGetCashPointDriversResponse = '@/CM_ORD_GET_CASH_POINT_DRIVERS_RESPONSE',
  OrdGetCashPointDriversFail = '@/CM_ORD_GET_CASH_POINT_DRIVERS_FAIL',

  OrdGetSentOpsStatusesRequest = '@/CM_ORD_GET_SENT_OPS_STATUSES_REQUEST',
  OrdGetSentOpsStatusesResponse = '@/CM_ORD_GET_SENT_OPS_STATUSES_RESPONSE',
  OrdGetSentOpsStatusesFail = '@/CM_ORD_GET_SENT_OPS_STATUSES_FAIL',

  // Replenishments
  IcsGetOrdersRequest = '@/CM_ICS_GET_ORDERS_REQUEST',
  IcsGetOrdersResponse = '@/CM_ICS_GET_ORDERS_RESPONSE',
  IcsGetOrdersResponseFail = '@/CM_ICS_GET_ORDERS_RESPONSE_FAIL',
  IcsSetOrdersPagingRequest = '@/CM_SET_ICS_ORDERS_PAGING_REQUEST',
  IcsSetOrdersPagingResponse = '@/CM_SET_ICS_ORDERS_PAGING_RESPONSE',
  IcsResetOrdersPage = '@/CM_ICS_RESET_ORDERS_PAGE',
  IcsSetFiltersModalOpen = '@/CM_ICS_SET_FILTERS_MODAL_OPEN',
  IcsSetSettingsModalOpen = '@/CM_ICS_SET_SETTINGS_MODAL_OPEN',
  IcsSetChangeReplenishmentTypeModalOpen = '@/CM_ICS_SET_CHANGE_REPLEN_TYPE_MODAL_OPEN',
  IcsSetDeclineModalOpen = '@/CM_ICS_SET_DECLINE_MODAL_OPEN',
  IcsRefreshOrdersPanel = '@/CM_ICS_REFRESH_ORDERS_PANEL',
  IcsFiltersSubmitRequest = '@/CM_ICS_FILTERS_SUBMIT_REQUEST',
  IcsSetOrdersFilter = '@/CM_ICS_SET_ORDERS_FILTER',
  IcsSetSelectedOrder = '@/CM_ICS_SET_SELECTED_ORDER',
  IcsCheckAllOrders = '@/CM_ICS_CHECK_ALL_ORDERS',
  IcsClearAllOrders = '@/CM_ICS_CLEAR_ALL_ORDERS',
  IcsSortOrdersTable = '@/CM_ICS_SORT_ORDERS_TABLE',
  IcsToggleOrder = '@/CM_ICS_TOGGLE_ORDER',
  IcsSetDocumentModalOpen = '@/CM_ICS_SET_DOCUMENT_MODAL_OPEN',
  IcsGetReportsRequest = '@/CM_GET_ICS_REPORTS_REQUEST',
  IcsGetReportsResponse = '@/CM_GET_ICS_REPORTS_RESPONSE',
  IcsGetReportsResponseFail = '@/CM_GET_ICS_REPORTS_RESPONSE_FAIL',
  IcsGetReportRequest = '@/CM_GET_ICS_REPORT_REQUEST',
  IcsGetReportResponse = '@/CM_GET_ICS_REPORT_RESPONSE',
  IcsGetReportResponseFail = '@/CM_GET_ICS_REPORT_RESPONSE_FAIL',
  IcsRefreshOrderRequest = '@/CM_ICS_REFRESH_ORDER_REQUEST',
  IcsRefreshOrderResponse = '@/CM_ICS_REFRESH_ORDER_RESPONSE',
  IcsRefreshOrderResponseFail = '@/CM_ICS_REFRESH_ORDER_RESPONSE_FAIL',
  IcsGetStagesDocRequest = '@/CM_ICS_GET_STAGES_DOC_REQUEST',
  IcsGetStagesDocResponse = '@/CM_ICS_GET_STAGES_DOC_RESPONSE',
  IcsSetSelectedSessionDoc = '@/CM_ICS_SET_SELECTED_SESSION_DOC',
  IcsDeclineOrdersRequest = '@/CM_ICS_DECLINE_ORDERS_REQUEST',
  IcsDeclineOrdersResponseFail = '@/CM_ICS_DECLINE_ORDERS_RESPONSE_FAIL',
  IcsChangeReplenTypeRequest = '@/CM_ICS_CHANGE_REPLEN_TYPE_REQUEST',
  IcsChangeReplenTypeResponseFail = '@/CM_ICS_CHANGE_REPLEN_TYPE_RESPONSE_FAIL',
  IcsAssignCollectorRequest = '@/CM_ICS_ASSIGN_COLLECTOR_REQUEST',
  IcsAssignCollectorResponseFail = '@/CM_ICS_ASSIGN_COLLECTOR_RESPONSE_FAIL',
  IcsGetOrderStagesRequest = '@/CM_ICS_GET_ORDER_STAGES_REQUEST',
  IcsGetOrderStagesResponse = '@/CM_ICS_GET_ORDER_STAGES_RESPONSE',
  IcsGetOrderStagesResponseFail = '@/CM_ICS_GET_ORDER_STAGES_RESPONSE_FAIL',
  IcsDeleteLastDocumentRequest = '@/CM_ICS_DELETE_LAST_DOCUMENT_REQUEST',
  IcsDeleteLastDocumentResponse = '@/CM_ICS_DELETE_LAST_DOCUMENT_RESPONSE',
  IcsDeleteLastDocumentResponseFail = '@/CM_ICS_DELETE_LAST_DOCUMENT_RESPONSE_FAIL',
  IcsSetOrderStageRequest = '@/CM_ICS_SET_ORDER_STAGE_REQUEST',
  IcsSetOrderStageResponse = '@/CM_ICS_SET_ORDER_STAGE_RESPONSE',
  IcsSetOrderStageResponseFail = '@/CM_ICS_SET_ORDER_STAGE_RESPONSE_FAIL',
  IcsHandleKeyPress = '@/CM_ICS_HANDLE_KEY_PRESS',

  IcsGetMissedReplenishmentRequest = '@/CM_ICS_GET_MISSED_REPLENISHMENT_REQUEST',
  IcsGetMissedReplenishmentFail = '@/CM_ICS_GET_MISSED_REPLENISHMENT_FAIL',

  IcsGetMissedReplenishmentCartResponse = '@/CM_ICS_MISSED_REPLENISHMENT_CHART_RESPONSE',

  IcsGetMissedReplenishmentEventsRequest = '@/CM_ICS_MISSED_REPLENISHMENT_EVENTS_REQUEST',
  IcsGetMissedReplenishmentEventsResponse = '@/CM_ICS_MISSED_REPLENISHMENT_EVENTS_RESPONSE',

  IcsSetMissedReplenishmentFromDate = '@/CM_ICS_MISSED_REPLENISHMENT_FROM_DATE',

  IcsSetMissedReplenishmentPagingRequest = '@/CM_ICS_SET_MISSED_REPLENISHMENT_PAGING_REQUEST',
  IcsSetMissedReplenishmentPagingResponse = '@/CM_ICS_SET_MISSED_REPLENISHMENT_PAGING_RESPONSE',

  IcsSortMissedReplenishmentEventsTable = '@/CM_ICS_SORT_MISSED_REPLENISHMENT_EVENTS_TABLE',
  IcsGetMissedReplenishmentCassettesRequest = '@/CM_ICS_GET_MISSED_REPLENISHMENT_CASSETTES_REQUEST',
  IcsGetMissedReplenishmentCassettesResponse = '@/CM_ICS_GET_MISSED_REPLENISHMENT_CASSETTES_RESPONSE',
  IcsGetMissedReplenishmentCassettesFail = '@/CM_ICS_GET_MISSED_REPLENISHMENT_CASSETTES_FAIL',

  IcsGetMissedReplenishmentCassettesUpdate = '@/CM_ICS_GET_MISSED_REPLENISHMENT_CASSETTES_UPDATE',

  IcsCreateMissedReplenishmentEventRequest = '@/CM_ICS_CREATE_MISSED_REPLENISHMENT_EVENT_REQUEST',
  IcsCreateMissedReplenishmentEventResponse = '@/CM_ICS_CREATE_MISSED_REPLENISHMENT_EVENT_RESPONSE',
  IcsCreateMissedReplenishmentEventFail = '@/CM_ICS_CREATE_MISSED_REPLENISHMENT_EVENT_FAIL',

  IcsApplyMissedReplenishmentEventRequest = '@/CM_ICS_APPLY_MISSED_REPLENISHMENT_EVENT_REQUEST',
  IcsApplyMissedReplenishmentEventResponse = '@/CM_ICS_APPLY_MISSED_REPLENISHMENT_EVENT_RESPONSE',
  IcsApplyMissedReplenishmentEventFail = '@/CM_ICS_APPLY_MISSED_REPLENISHMENT_EVENT_FAIL',

  IcsSetInitialMissedReplenishment = '@/CM_ICS_SET_INITIAL_MISSED_REPLENISHMENT',
  IcsSetMissedReplenishmentEventType = '@/CM_ICS_SET_MISSED_REPLENISHMENT_EVENT_TYPE',

  IcsCalcMissedReplenishmentCassettesTotalRequest = '@/CM_ICS_MR_CASSETTES_TOTAL_REQUEST',
  IcsCalcMissedReplenishmentCassettesTotalResponse = '@/CM_ICS_MR_CASSETTES_TOTAL_RESPONSE',
  IcsCalcMissedReplenishmentCassettesTotalFail = '@/CM_ICS_MR_CASSETTES_TOTAL_FAIL',

  IcsSetInitialMissedReplenishmentCassettes = '@/CM_ICS_MR_SET_INITIAL_MISSED_REPLENISHMENT_CASSETTES',

  // Balancing
  BalGetCashPointsRequest = '@/CM_BAL_GET_CASH_POINTS_REQUEST',
  BalSetSelectedCashPoint = '@/CM_BAL_SET_SELECTED_CASH_POINT',
  BalGetCashPointsResponse = '@/CM_BAL_GET_CASH_POINTS_RESPONSE',
  BalGetCashPointsResponseFail = '@/CM_BAL_GET_CASH_POINTS_RESPONSE_FAIL',
  BalSetCashPointsPagingRequest = '@/CM_BAL_SET_CASH_POINTS_PAGING_REQUEST',
  BalSetCashPointsPagingResponse = '@/CM_BAL_SET_CASH_POINTS_PAGING_RESPONSE',

  BalSetFiltersModalOpen = '@/CM_BAL_SET_FILTERS_MODAL_OPEN',
  BalSetDocumentModalOpen = '@/CM_BAL_SET_DOCUMENT_MODAL_OPEN',
  BalSetSettingsModalOpen = '@/CM_BAL_SET_SETTINGS_MODAL_OPEN',
  BalSetCashBalancingModalOpen = '@/CM_BAL_SET_CASH_BALANCING_MODAL_OPEN',
  BalFilterSubmitRequest = '@/CM_BAL_FILTER_SUBMIT_REQUEST',
  BalSetCashPointsFilter = '@/CM_BAL_SET_CASH_POINTS_FILTER',
  BalResetCashPointsPage = '@/CM_BAL_RESET_CASH_POINTS_PAGE',
  BalToggleCashPoint = '@/CM_BAL_TOGGLE_CASH_POINT',
  BalSortTable = '@/CM_BAL_SORT_TABLE',
  BalCheckAllCashPoints = '@/CM_BAL_CHECK_ALL_CASH_POINTS',
  BalClearAllCashPoints = '@/CM_BAL_CLEAR_ALL_CASH_POINTS',
  BalGetReportsRequest = '@/CM_BAL_GET_REPORTS_REQUEST',
  BalGetReportsResponse = '@/CM_BAL_GET_REPORTS_RESPONSE',
  BalGetReportsResponseFail = '@/CM_BAL_GET_REPORTS_RESPONSE_FAIL',
  BalRefreshCashPointsPanel = '@/CM_BAL_REFRESH_CASH_POINTS_PANEL',
  BalGetReportRequest = '@/CM_BAL_GET_REPORT_REQUEST',
  BalGetReportResponse = '@/CM_BAL_GET_REPORT_RESPONSE',
  BalGetReportResponseFail = '@/CM_BAL_GET_REPORT_RESPONSE_FAIL',
  BalGetCashPointReportsRequest = '@/CM_BAL_GET_CASH_POINT_REPORTS_REQUEST',
  BalGetCashPointReportsResponse = '@/CM_BAL_GET_CASH_POINT_REPORTS_RESPONSE',
  BalGetCashPointReportsResponseFail = '@/CM_BAL_GET_CASH_POINT_REPORTS_RESPONSE_FAIL',
  BalGetCashPointReportRequest = '@/CM_BAL_GET_CASH_POINT_REPORT_REQUEST',
  BalGetCashPointReportResponse = '@/CM_BAL_GET_CASH_POINT_REPORT_RESPONSE',
  BalGetCashPointReportResponseFail = '@/CM_BAL_GET_CASH_POINT_REPORT_RESPONSE_FAIL',
  BalAssignCashierRequest = '@/CM_BAL_ASSIGN_CASHIER_REQUEST',
  BalAssignCashierResponse = '@/CM_BAL_ASSIGN_CASHIER_RESPONSE',

  BalHandleKeyPress = '@/CM_BAL_HANDLE_KEY_PRESS',
  BalHandleCashBalanceModalKeyPress = '@/CM_BAL_HANDLE_CASH_BALANCE_MODAL_KEY_PRESS',

  BalGetInfoRequest = '@/CM_BAL_GET_INFO_REQUEST',
  BalGetInfoResponse = '@/CM_BAL_GET_INFO_RESPONSE',
  BalGetInfoFail = '@/CM_BAL_GET_INFO_FAIL',

  BalGetCassettesRequest = '@/CM_BAL_GET_CASSETTES_REQUEST',
  BalGetCassettesResponse = '@/CM_BAL_GET_CASSETTES_RESPONSE',
  BalGetCassettesFail = '@/CM_BAL_GET_CASSETTES_FAIL',

  BalSetSelectedEndCycle = '@/CM_BAL_SET_SELECTED_END_CYCLE',
  BalSetOnlySelectedEndCycle = '@/CM_BAL_SET__ONLY_SELECTED_END_CYCLE',

  BalSetSelectedUnloadedCassettes = '@/CM_BAL_SET_SELECTED_UNLOADED_CASSETTES',
  BalSetSelectedNotLoadedCassettes = '@/CM_BAL_SET_SELECTED_NOT_LOADED_CASSETTES',

  BalSetUnloadedSelectedCassette = '@/CM_BAL_SET_UNLOADED_SELECTED_CASSETTE',

  BalApplyBalUnloadedCassettesRequest = '@/CM_BAL_APPLY_UNLOADED_CASSETTES_REQUEST',
  BalApplyBalUnloadedCassettesResponse = '@/CM_BAL_APPLY_NOT_LOADED_CASSETTES_RESPONSE',
  BalApplyBalUnloadedCassettesFail = '@/CM_BAL_APPLY_UNLOADED_CASSETTES_FAIL',

  BalApplyBalNotLoadedCassettesRequest = '@/CM_BAL_APPLY_NOT_LOADED_CASSETTES_REQUEST',
  BalApplyBalNotLoadedCassettesResponse = '@/CM_BAL_APPLY_NOT_LOADED_CASSETTES_RESPONSE',
  BalApplyBalNotLoadedCassettesFail = '@/CM_BAL_APPLY_NOT_LOADED_CASSETTES_FAIL',

  BalCalcUnloadedTotalsResponse = '@/CM_BAL_CALC_UNLOADED_TOTALS_RESPONSE',
  BalCalcUnloadedTotalsFail = '@/CM_BAL_CALC_UNLOADED_TOTALS_FAIL',

  BalCalcNotLoadedTotalsResponse = '@/CM_BAL_CALC_NOT_LOADED_TOTALS_RESPONSE',
  BalCalcNotLoadedTotalsFail = '@/CM_BAL_CALC_NOT_LOADED_TOTALS_FAIL',

  BalSetUnloadedTotalSessionId = '@/CM_BAL_SET_UNLOADED_TOTAL_SESSION_ID',

  BalSetSortersType = '@/CM_BAL_SET_SORTERS_TYPE',

  BalOpenCassettesModal = '@CM/BAL_OPEN_CASSETTE_MODAL_REQUEST',

  BalModifyCassettesRequest = '@/CM_BAL_MODIFY_CASSETTES_REQUEST',
  BalModifyCassettesResponse = '@/CM_BAL_MODIFY_CASSETTES_RESPONSE',
  BalModifyCassettesFail = '@/CM_BAL_MODIFY_CASSETTES_FAIL',

  BalRemoveCassetteRequest = '@/CM_BAL_REMOVE_CASSETTE_REQUEST',
  BalRemoveCassetteResponse = '@/CM_BAL_REMOVE_CASSETTE_RESPONSE',
  BalRemoveCassetteFail = '@/CM_BAL_REMOVE_CASSETTE_FAIL',

  // Maps
  MapCashPointsRequest = '@/CM_MAP_CASH_POINTS_REQUEST',
  MapCashPointsResponse = '@/CM_MAP_CASH_POINTS_RESPONSE',
  MapCashPointsFail = '@/CM_MAP_CASH_POINTS_FAIL',

  MapHandleFilterModal = '@/CM_MAP_HANDLE_FILTER_MODAL',
  MapFilterSubmitRequest = '@/CM_MAP_FILTER_SUBMIT_REQUEST',
  MapSetCashPointsFilter = '@/CM_MAP_SET_CASH_POINTS_FILTER',

  MapHandleDetailedInformationPanel = '@/CM_MAP_HANDLE_DETAILED_INFORMATION_PANEL',
  MapSetInitialDetailedInformation = '@/CM_MAP_SET_INITIAL_DETAILED_INFORMATION',

  MapGetSelectedCashPointInformationRequest = '@/CM_MAP_GET_SELECTED_CASH_POINT_INFORMATION_REQUEST',
  MapGetSelectedCashPointInformationRespose = '@/CM_MAP_GET_SELECTED_CASH_POINT_INFORMATION_RESPONSE',
  MapGetSelectedCashPointInformationFail = '@/CM_MAP_GET_SELECTED_CASH_POINT_INFORMATION_FAIL',

  MapRefresh = '@/CM_MAP_REFRESH',

  MapHandleKeyPress = '@/CM_MAP_HANDLE_KEY_PRESS',

  // Analysis
  AnalysisSetFiltersModalOpen = '@/CM_SET_ANALYSIS_FILTERS_MODAL_OPEN',
  AnalysisSetFilter = '@/CM_SET_ANALYSIS_FILTER',
  AnalysisFilterSubmitRequest = '@/CM_ANALYSIS_FILTER_SUBMIT_REQUEST',

  AnalysisSetDocumentModalOpen = '@/CM_SET_ANALYSIS_DOCUMENT_MODAL_OPEN',
  AnalysisGetReportRequest = '@/CM_ANALYSIS_GET_REPORT_REQUEST',
  AnalysisGetReportResponse = '@/CM_ANALYSIS_GET_REPORT_RESPONSE',
  AnalysisGetReportResponseFail = '@/CM_ANALYSIS_GET_REPORT_RESPONSE_FAIL',
  AnalysisGetReportsRequest = '@/CM_ANALYSIS_GET_REPORTS_REQUEST',
  AnalysisGetReportsResponse = '@/CM_ANALYSIS_GET_REPORTS_RESPONSE',
  AnalysisGetReportsResponseFail = '@/CM_ANALYSIS_GET_REPORTS_RESPONSE_FAIL',

  AnalysisSetSelectedChartsType = '@/CM_ANALYSIS_SET_SELECTED_CHARTS_TYPE',
  AnalysisGetChartsRequest = '@/CM_ANALYSIS_GET_CHARTS_REQUEST',
  AnalysisGetChartsResponse = '@/CM_ANALYSIS_GET_CHART_RESPONSE',

  // cit
  CitPlnStopLoadingAfterError = '@/CM_CIT_PLN_STOP_LOADING_AFTER_ERROR',

  CitPlnAcceptCashPointRequest = '@/CM_CIT_PLN_ACCEPT_CASH_POINT_REQUEST',
  CitPlnCalculateCashPointRequest = '@/CM_CIT_PLN_CALCULATE_CASH_POINT_REQUEST',
  CitPlnCalculateCashPointFail = '@/CM_CIT_PLN_CALCULATE_CASH_POINT_FAIL',
  CitPlnCreateWorkOrderRequest = '@/CM_CIT_PLN_CREATE_WORK_ORDER_REQUEST',
  CitPlnApproveWorkOrderRequest = '@/CM_CIT_PLN_APPROVE_WORK_ORDER_REQUEST',

  CitPlnDeleteWorkOrderRequest = '@/CM_CIT_PLN_DELETE_WORK_ORDER_REQUEST',
  CitPlnDeleteWorkOrderResponse = '@/CM_CIT_PLN_DELETE_WORK_ORDER_RESPONSE',
  CitPlnDeleteWorkOrderFail = '@/CM_CIT_PLN_DELETE_WORK_ORDER_FAIL',

  CitPlnDeclineCashOrderRequest = '@/CM_CIT_PLN_DECLINE_CASH_ORDER_REQUEST',
  CitPlnDeclineCashPlanRequest = '@/CM_CIT_PLN_DECLINE_CASH_PLAN_REQUEST',
  CitPlnDeleteCashOrderRequest = '@/CM_CIT_PLN_DELETE_CASH_ORDER_REQUEST',
  CitPlnFilterSubmitRequest = '@/CM_CIT_PLN_FILTER_SUBMIT_REQUEST',
  CitPlnGetCashPointPropertyRequest = '@/CM_CIT_PLN_GET_CASH_POINT_PROPERTY_REQUEST',
  CitPlnGetCashPointPropertyResponse = '@/CM_CIT_PLN_GET_CASH_POINT_PROPERTY_RESPONSE',

  CitPlnGetCashPointRequest = '@/CM_CIT_PLN_GET_CASH_POINT_REQUEST',
  CitPlnGetCashPointResponse = '@/CM_CIT_PLN_GET_CASH_POINT_RESPONSE',
  CitPlnGetCashPointFail = '@/CM_CIT_PLN_GET_CASH_POINT_FAIL',

  CitPlnGetCashPointsRequest = '@/CM_CIT_PLN_GET_CASH_POINTS_REQUEST',
  CitPlnGetCashPointsResponse = '@/CM_CIT_PLN_GET_CASH_POINTS_RESPONSE',
  CitPlnGetCashPointsResponseFail = '@/CM_CIT_PLN_GET_CASH_POINTS_RESPONSE_FAIL',
  CitPlnGetReportRequest = '@/CM_CIT_GET_PLN_REPORT_REQUEST',
  CitPlnGetReportResponse = '@/CM_CIT_GET_PLN_REPORT_RESPONSE',
  CitPlnGetReportResponseFail = '@/CM_CIT_GET_PLN_REPORT_RESPONSE_FAIL',
  CitPlnHandleKeyPress = '@/CM_CIT_HANDLE_PLN_KEY_PRESS',
  CitPlnGetReportsRequest = '@/CM_CIT_GET_PLN_REPORTS_REQUEST',
  CitPlnGetReportsResponse = '@/CM_CIT_GET_PLN_REPORTS_RESPONSE',
  CitPlnGetReportsResponseFail = '@/CM_CIT_GET_PLN_REPORTS_RESPONSE_FAIL',

  CitPlnGetWorkOrdersRequest = '@/CM_CIT_GET_PLN_WORK_ORDERS_REQUEST',
  CitPlnGetWorkOrdersResponse = '@/CM_CIT_GET_PLN_WORK_ORDERS_RESPONSE',
  CitPlnGetWorkOrdersFail = '@/CM_CIT_GET_PLN_WORK_ORDERS_FAIL',

  CitPlnRefreshCashPointsPanel = '@/CM_CIT_PLN_REFRESH_CASH_POINTS_PANEL',
  CitPlnSetCalculateModalOpen = '@/CM_CIT_PLN_SET_CALCULATE_REQUEST',
  CitPlnSetUnloadConfirmationModalOpen = '@/CM_CIT_PLN_SET_UNLOAD_CONFIRMATION_MODAL_OPEN',
  CitPlnSetCashPointPropertyRequest = '@/CM_CIT_PLN_SET_CASH_POINT_PROPERTY_REQUEST',
  CitPlnSetCashPointPropertyFail = '@/CM_CIT_PLN_SET_CASH_POINT_PROPERTY_FAIL',
  CitPlnSetCashPointsFilter = '@/CM_CIT_PLN_SET_CASH_POINTS_FILTER',
  CitPlnSetCashPointsPagingRequest = '@/CM_CIT_PLN_SET_CASH_POINTS_PAGING_REQUEST',
  CitPlnSetCashPointsPagingResponse = '@/CM_CIT_PLN_SET_CASH_POINTS_PAGING_RESPONSE',
  CitPlnSetCheckedWorkOrder = '@/CM_CIT_PLN_SET_CHECKED_WORK_ORDER',
  CitPlnSetCheckedWorkOrderSingle = '@/CM_CIT_PLN_SET_CHECKED_WORK_ORDER_SINGLE',
  CitPlnSetDeclineModalsOpen = '@/CM_CIT_PLN_SET_DECLINE_MODALS_OPEN',
  CitPlnSetDeclineNoteModalOpen = '@/CM_CIT_PLN_SET_DECLINE_NOTE_MODAL_OPEN',
  CitPlnSetDetailsSelectedTab = '@/CM_CIT_PLN_SET_DETAILS_SELECTED_TAB',
  CitPlnSetDocumentModalOpen = '@/CM_CIT_PLN_SET_DOCUMENT_MODAL_OPEN',
  CitPlnSetFiltersModalOpen = '@/CM_CIT_PLN_SET_FILTERS_MODAL_OPEN',
  CitPlnSetMainSettingsModalOpen = '@/CM_CIT_PLN_SET_MAIN_SETTINGS_MODAL_OPEN',
  CitPlnSetSelectedCashPoint = '@/CM_CIT_PLN_SET_SELECTED_CASH_POINT',
  CitPlnSetUnloadingRequest = '@/CM_CIT_PLN_SET_UNLOADING_REQUEST',
  CitPlnSetUpperLimitsModalOpen = '@/CM_CIT_PLN_SET_UPPER_LIMITS_MODAL_OPEN',
  CitPlnSetUpperLimitsRequest = '@/CM_CIT_PLN_SET_UPPER_LIMITS_REQUEST',
  CitPlnSetWorkOrdersFiltersModalOpen = '@/CM_CIT_PLN_SET_WORK_ORDERS_FILTERS_MODAL_OPEN',
  CitPlnSetWorkOrdersDocumentModalOpen = '@/CM_CIT_PLN_SET_WORK_ORDERS_DOCUMENT_MODAL_OPEN',
  CitPlnSortCashPointsTable = 'CM_CIT_PLN_SORT_CASH_POINTS_TABLE',
  CitPlnSortWorkOrdersTable = 'CM_CIT_PLN_SORT_WORK_ORDERS_TABLE',

  CitPlnWorkOrdersFilterSubmitRequest = '@/CM_CIT_PLN_WO_FILTER_SUBMIT_REQUEST',

  CitPlnWorkOrdersGetReportRequest = '@/CM_CIT_GET_PLN_WO_REPORT_REQUEST',
  CitPlnWorkOrdersGetReportResponse = '@/CM_CIT_GET_PLN_WO_REPORT_RESPONSE',
  CitPlnWorkOrdersGetReportResponseFail = '@/CM_CIT_GET_PLN_WO_REPORT_RESPONSE_FAIL',
  CitPlnWorkOrdersGetReportsRequest = '@/CM_CIT_GET_PLN_WO_REPORTS_REQUEST',
  CitPlnWorkOrdersGetReportsResponse = '@/CM_CIT_GET_PLN_WO_REPORTS_RESPONSE',
  CitPlnWorkOrdersGetReportsResponseFail = '@/CM_CIT_GET_PLN_WO_REPORTS_RESPONSE_FAIL',

  CitPlnSetWorkOrdersPaging = '@/CM_CIT_PLN_SET_WORK_ORDERS_PAGING',

  CitPlnSetVaultTransactionRequest = '@/CM_CIT_PLN_SET_VAULT_TRANSACTION_REQUEST',
  CitPlnSetVaultTransactionResponse = '@/CM_CIT_PLN_SET_VAULT_TRANSACTION_RESPONSE',
  CitPlnSetVaultTransactionFail = '@/CM_CIT_PLN_SET_VAULT_TRANSACTION_FAIL',

  CitPlnSetWorkOrderName = '@/CM_CIT_PLN_SET_WORK_ORDER_NAME',

  CitPlnGetWOCassettesRequest = '@/CM_CIT_GET_PLN_WO_CASSETTES_REQUEST',
  CitPlnGetWOCassettesResponse = '@/CM_CIT_GET_PLN_WO_CASSETTES_RESPONSE',
  CitPlnGetWOCassettesFail = '@/CM_CIT_GET_PLN_WO_CASSETTES_FAIL',

  CitPlnRefreshWOCassettes = '@/CM_CIT_PLN_REFRESH_WO_CASSETTES',
  CitPlnSortWOCassettes = '@/CM_CIT_PLN_SORT_WO_CASSETTES',

  CitPlnGetRepairOrderRequest = '@/CM_CIT_PLN_GET_REPAIR_ORDER_REQUEST',
  CitPlnGetRepairOrderResponse = '@/CM_CIT_PLN_GET_REPAIR_ORDER_RESPONSE',
  CitPlnGetRepairOrderResponseFail = '@/CM_CIT_PLN_GET_REPAIR_ORDER_RESPONSE_FAIL',
  CitPlnGetUnloadOrderRequest = '@/CM_CIT_PLN_GET_UNLOAD_ORDER_REQUEST',
  CitPlnGetUnloadOrderResponse = '@/CM_CIT_PLN_GET_UNLOAD_ORDER_RESPONSE',
  CitPlnGetUnloadOrderResponseFail = '@/CM_CIT_PLN_GET_UNLOAD_ORDER_RESPONSE_FAIL',
  CitPlnRefreshRepairOrder = '@/CM_CIT_PLN_REFRESH_REPAIR_ORDER',
  CitPlnRefreshUnloadOrder = '@/CM_CIT_PLN_REFRESH_UNLOAD_ORDER',
  CitPlnSetRepairOrderName = '@/CM_CIT_PLN_SET_REPAIR_ORDER_NAME',
  CitPlnSetUnloadOrderName = '@/CM_CIT_PLN_SET_UNLOAD_ORDER_NAME',
  CitPlnSortRepairOrder = '@/CM_CIT_PLN_SORT_REPAIR_ORDER',
  CitPlnSortUnloadOrder = '@/CM_CIT_PLN_SORT_UNLOAD_ORDER',
  CitPlnApplyUnloadOrder = '@/CM_CIT_PLN_APPLY_UNLOAD_ORDER',
  CitPlnApplyRepairOrder = '@/CM_CIT_PLN_APPLY_REPAIR_ORDER',

  CitPlnSetSelectedWO = '@/CM_CIT_PLN_SET_SELECTED_WO',
  CitPlnSetSelectedWOInitial = '@/CM_CIT_PLN_SET_SELECTED_WO_INITIAL',
  CitPlnSetWOReportsModal = '@/CM_CIT_PLN_SET_WO_REPORTS_MODAL',

  CitPlnGetWOReportsRequest = '@/CM_CIT_PLN_GET_WO_REPORTS_REQUEST',
  CitPlnGetWOReportsResponse = '@/CM_CIT_PLN_GET_WO_REPORTS_RESPONSE',
  CitPlnGetWOReportsFail = '@/CM_CIT_PLN_GET_WO_REPORTS_FAIL',

  CitPlnSetWOFilter = '@/CM_CIT_PLN_SET_WO_FILTER',

  CitPlnSetSelectedUnloadOrder = '@/CM_CIT_PLN_SET_SELECTED_UNLOAD_ORDER',
  CitPlnSetUnloadOrderReportsModal = '@/CM_CIT_PLN_SET_UNLOAD_ORDER_REPORTS_MODAL',
  CitPlnGetUnloadOrderReportsRequest = '@/CM_CIT_PLN_GET_UNLOAD_ORDER_REPORTS_REQUEST',
  CitPlnGetUnloadOrderReportsResponse = '@/CM_CIT_PLN_GET_UNLOAD_ORDER_REPORTS_RESPONSE',
  CitPlnGetUnloadOrderReportsFail = '@/CM_CIT_PLN_GET_UNLOAD_ORDER_REPORTS_FAIL',
  CitPlnGetUnloadOrderReportRequest = '@/CM_CIT_PLN_GET_UNLOAD_ORDER_REPORT_REQUEST',
  CitPlnGetUnloadOrderReportResponse = '@/CM_CIT_PLN_GET_UNLOAD_ORDER_REPORT_RESPONSE',
  CitPlnGetUnloadOrderReportFail = '@/CM_CIT_PLN_GET_UNLOAD_ORDER_REPORT_FAIL',

  CitPlnSetSelectedRepairOrder = '@/CM_CIT_PLN_SET_SELECTED_REPAIR_ORDER',
  CitPlnSetRepairOrderReportsModal = '@/CM_CIT_PLN_SET_REPAIR_ORDER_REPORTS_MODAL',
  CitPlnGetRepairOrderReportsRequest = '@/CM_CIT_PLN_GET_REPAIR_ORDER_REPORTS_REQUEST',
  CitPlnGetRepairOrderReportsResponse = '@/CM_CIT_PLN_GET_REPAIR_ORDER_REPORTS_RESPONSE',
  CitPlnGetRepairOrderReportsFail = '@/CM_CIT_PLN_GET_REPAIR_ORDER_REPORTS_FAIL',
  CitPlnGetRepairOrderReportRequest = '@/CM_CIT_PLN_GET_REPAIR_ORDER_REPORT_REQUEST',
  CitPlnGetRepairOrderReportResponse = '@/CM_CIT_PLN_GET_REPAIR_ORDER_REPORT_RESPONSE',
  CitPlnGetRepairOrderReportFail = '@/CM_CIT_PLN_GET_REPAIR_ORDER_REPORT_FAIL',

  CitPlnGetWOReportRequest = '@/CM_CIT_PLN_GET_WO_REPORT_REQUEST',
  CitPlnGetWOReportResponse = '@/CM_CIT_PLN_GET_WO_REPORT_RESPONSE',
  CitPlnGetWOReportFail = '@/CM_CIT_PLN_GET_WO_REPORT_FAIL',

  CitPlnSetIsAllOrders = '@/CM_CIT_PLN_SET_IS_ALL_ORDERS',

  CitPlnSetEditedCassettes = '@/CM_CIT_PLN_SET_EDITED_CASSETTES',

  CitPlnApplyWOCassettesStamps = '@/CM_CIT_PLN_APPLY_WO_CASSETTES_STAMPS',
  CitPlnApplyWOCassettesStampsFail = '@/CM_CIT_PLN_APPLY_WO_CASSETTES_STAMPS_FAIL',

  CitPlnClearCheckedOrders = '@/CM_CIT_PLN_CLEAR_CHECKED_ORDERS',

  CitPlnSetSelectedWorkOrder = '@/CM_CIT_PLN_SET_SELECTED_WORK_ORDER',

  CitSortCassettesSets = '@/CM_CIT_SORT_CASSETTES_SETS',

  // Cassette sets
  CitGetCassetteSetsRequest = '@/CM_CIT_GET_CASSETTE_SETS_REQUEST',
  CitGetCassetteSetsResponse = '@/CM_CIT_GET_CASSETTE_SETS_RESPONSE',
  CitGetCassetteSetsFail = '@/CM_CIT_GET_CASSETTE_SETS_FAIL',

  CitSetCassetteSetsModalOpen = '@/CM_CIT_SET_CASSETTES_SETS_MODAL_OPEN',
  CitSetSelectedCassetteSet = '@/CM_CIT_SET_SELECTED_CASSETTE_SET',

  CitAddNewCassetteSetRequest = '@/CM_CIT_ADD_NEW_CASSETTE_SET_REQUEST',
  CitAddNewCassetteSetFail = '@/CM_CIT_ADD_NEW_CASSETTE_SET_FAIL',

  CitUpdateNewCassetteSetRequest = '@/CM_CIT_UPDATE_NEW_CASSETTE_SET_REQUEST',
  CitUpdateNewCassetteSetFail = '@/CM_CIT_UPDATE_NEW_CASSETTE_SET_FAIL',

  CitPlnGetCassetteTypesRequest = '@/CM_CIT_PLN_GET_CASSETTE_TYPES_REQUEST',
  CitPlnGetCassetteTypesResponse = '@/CM_CIT_PLN_GET_CASSETTE_TYPES_RESPONSE',

  CitRemoveCassetteSetRequest = '@/CM_CIT_REMOVE_CASSETTE_SET_REQUEST',
  CitRemoveCassetteSetResponse = '@/CM_CIT_REMOVE_CASSETTE_SET_RESPONSE',
  CitRemoveCassetteSetFail = '@/CM_CIT_REMOVE_CASSETTE_SET_FAIL',

  // Teams
  CitPlnGetTeamsRequest = '@/CM_CIT_PLN_GET_TEAMS_REQUEST',
  CitPlnGetTeamsResponse = '@/CM_CIT_PLN_GET_TEAMS_RESPONSE',
  CitPlnGetTeamsFail = '@/CM_CIT_PLN_GET_TEAMS_FAIL',

  CitPlnDeleteTeamRequest = '@/CM_CIT_PLN_DELETE_TEAM_REQUEST',
  CitPlnDeleteTeamFail = '@/CM_CIT_PLN_DELETE_TEAM_FAIL',

  CitPlnSetSelectedTeam = '@/CM_CIT_PLN_SET_SELECTED_TEAM',

  CitPlnSetTeamsModalOpen = '@/CM_CIT_PLN_SET_TEAMS_MODAL_OPEN',
  CitPlnSetCheckedTeams = '@/CM_CIT_PLN_SET_CHECKED_TEAMS',
  CitPlnSortTeamsTable = 'CM_CIT_PLN_SORT_TEAMS_TABLE',

  CitPlnSetCassetteValidation = 'CM_CIT_PLN_SET_CASSETTE_VALIDATION',
  CitPlnSetInitialCassetteValidation = 'CM_CIT_PLN_SET_INITIAL_CASSETTE_VALIDATION',

  // Shared
  ShrGetCassettesPropsRequest = '@/CM_SHR_GET_CASSETTES_PROPS_REQUEST',
  ShrGetCassettesPropsResponse = '@/CM_SHR_GET_CASSETTES_PROPS_RESPONSE',
  ShrGetCassettesPropsResponseFail = '@/CM_SHR_GET_CASSETTES_PROPS_RESPONSE_FAIL',
  ShrSetCassettesPropsRequest = '@/CM_SHR_SET_CASSETTES_PROPS_REQUEST',
  ShrSetCassettesPropsResponse = '@/CM_SHR_SET_CASSETTES_PROPS_RESPONSE',
  ShrSetCassettesPropsResponseFail = '@/CM_SHR_SET_CASSETTES_PROPS_RESPONSE_FAIL',
  ShrSetEditCassettesPropsModalOpen = '@/CM_SET_SHR_EDIT_CASSETTES_MODAL_OPEN',
  ShrSetCashPointPriority = '@/CM_SHR_SET_CASH_POINT_PRIORITY',
  ShrGetSettingsRequest = '@/CM_SHR_GET_SETTINGS_REQUEST',
  ShrGetSettingsResponse = '@/CM_SHR_GET_SETTINGS_RESPONSE',
  ShrGetSettingsResponseFail = '@/CM_SHR_GET_SETTINGS_RESPONSE_FAIL',
  ShrSetDeclineNoteState = '@/CM_SHR_SET_DECLINE_NOTE_STATE',

  // [START] LICENSE ---------------------------------------------------------
  AdmSetSelectedLicensePanel = '@/CM_ADM_SET_SELECTED_LICENSE_PANEL',

  AdmGetLicenseRequest = '@/CM_ADM_GET_LICENSE_REQUEST',
  AdmGetLicenseResponse = '@/CM_ADM_GET_LICENSE_RESPONSE',
  AdmGetLicenseFail = '@/CM_ADM_GET_LICENSE_FAIL',

  AdmSetLicenseFilter = '@/CM_ADM_SET_LICENSE_FILTER',

  GetAdmLicenseReportsRequest = '@/CM_ADM_GET_LICENSE_REPORTS_REQUEST',
  GetAdmLicenseReportsResponse = '@/CM_ADM_GET_LICENSE_REPORTS_RESPONSE',
  GetAdmLicenseReportsFail = '@/CM_ADM_GET_LICENSE_REPORTS_FAIL',

  GetAdmLicenseReportRequest = '@/CM_ADM_GET_LICENSE_REPORT_REQUEST',
  GetAdmLicenseReportResponse = '@/CM_ADM_GET_LICENSE_REPORT_RESPONSE',
  GetAdmLicenseReportFail = '@/CM_ADM_GET_LICENSE_REPORT_FAIL',

  ResetAdmLicenseReports = '@/CM_ADM_RESET_LICENSE_REPORTS',
  // [END] LICENSE ---------------------------------------------------------

  // CIT Balancing
  CitBalSetFiltersModalOpen = '@/CM_CIT_BAL_SET_FILTERS_MODAL_OPEN',
  CitBalSetEventsViewModalOpen = '@/CM_CIT_BAL_SET_EVENTS_VIEW_MODAL_OPEN',
  CitBalSetAccountsModalOpen = '@/CM_CIT_BAL_SET_ACCOUNTS_MODAL_OPEN',
  CitBalSetReportsModalOpen = '@/CM_CIT_BAL_SET_REPORTS_MODAL_OPEN',
  CitBalSetConfirmModalOpen = '@/CM_CIT_BAL_SET_CONFIRM_MODAL_OPEN',
  CitBalFilterSubmitRequest = '@/CM_CIT_BAL_FILTER_SUBMIT_REQUEST',
  CitBalSetCashPointsFilter = '@/CM_CIT_BAL_SET_CASH_POINTS_FILTER',
  CitBalGetCashPointsRequest = '@/CM_CIT_BAL_GET_CASH_POINTS_REQUEST',
  CitBalGetCashPointsResponse = '@/CM_CIT_BAL_GET_CASH_POINTS_RESPONSE',
  CitBalGetCashPointsFail = '@/CM_CIT_BAL_GET_CASH_POINTS_FAIL',
  CitBalResetCashPointsPage = '@/CM_CIT_BAL_RESET_CASH_POINTS_PAGE',
  CitBalSetSelectedCashPoint = '@/CM_CIT_BAL_SET_SELECTED_CASH_POINT',
  CitBalClearAllCashPoints = '@/CM_CIT_BAL_CLEAR_ALL_CASH_POINTS',
  CitBalSetCashPointsPaging = '@/CM_CIT_BAL_SET_CASH_POINTS_PAGING',
  CitBalToggleCashPoint = '@/CM_CIT_BAL_TOGGLE_CASH_POINT',
  CitBalCheckAllCashPoints = '@/CM_CIT_BAL_CHECK_ALL_CASH_POINTS',
  CitBalRefreshCashPoints = '@/CM_CIT_BAL_REFRESH_CASH_POINTS',
  CitBalOpenColumnsSettingsModal = '@/CM_CIT_BAL_OPEN_COLUMNS_SETTINGS_MODAL',
  CitBalSortTable = '@/CM_CIT_BAL_SORT_TABLE',
  CitBalSetCashBalancingModalOpen = '@/CM_CIT_BAL_SET_CASH_BALANCING_MODAL_OPEN',
  CitBalSetAssetVaultModalOpen = '@/CM_CIT_BAL_SET_ASSET_VAULT_MODAL_OPEN',

  CitBalGetInfoRequest = '@/CM_CIT_BAL_GET_INFO_REQUEST',
  CitBalGetInfoResponse = '@/CM_CIT_BAL_GET_INFO_RESPONSE',
  CitBalGetInfoFail = '@/CM_CIT_BAL_GET_INFO_FAIL',

  CitBalGetCassettesRequest = '@/CM_CIT_BAL_GET_CASSETTES_REQUEST',
  CitBalGetCassettesResponse = '@/CM_CIT_BAL_GET_CASSETTES_RESPONSE',
  CitBalGetCassettesFail = '@/CM_CIT_BAL_GET_CASSETTES_FAIL',

  CitBalApplyBalUnloadedCassettesRequest = '@/CM_CIT_BAL_APPLY_UNLOADED_CASSETTES_REQUEST',
  CitBalApplyBalUnloadedCassettesResponse = '@/CM_CIT_BAL_APPLY_NOT_LOADED_CASSETTES_RESPONSE',

  CitBalSetSelectedUnloadedCassettes = '@/CM_CIT_BAL_SET_SELECTED_UNLOADED_CASSETTES',

  CitBalCalcUnloadedTotalsResponse = '@/CM_CIT_BAL_CALC_UNLOADED_TOTALS_RESPONSE',

  CitBalGetEventsRequest = '@/CM_CIT_BAL_GET_EVENTS_REQUEST',
  CitBalGetEventsFail = '@/CM_CIT_BAL_GET_EVENTS_FAIL',

  CitBalApplyEventRequest = '@/CM_CIT_BAL_APPLY_EVENT_REQUEST',
  CitBalApplyEventResponse = '@/CM_CIT_BAL_APPLY_EVENT_RESPONSE',
  CitBalApplyEventFail = '@/CM_CIT_BAL_APPLY_EVENT_FAIL',

  CitBalCloseCashOrderRequest = '@/CM_CIT_BAL_CLOSE_CASH_ORDER_REQUEST',
  CitBalCloseCashOrderResponse = '@/CM_CIT_BAL_CLOSE_CASH_ORDER_RESPONSE',
  CitBalCloseCashOrderFail = '@/CM_CIT_BAL_CLOSE_CASH_ORDER_FAIL',

  CitBalGetAccountsRequest = '@/CM_CIT_BAL_GET_ACCOUNTS_REQUEST',
  CitBalGetAccountsResponse = '@/CM_CIT_BAL_GET_ACCOUNTS_RESPONSE',
  CitBalGetAccountsFail = '@/CM_CIT_BAL_GET_ACCOUNTS_FAIL',

  CitBalUpdateAccountsRequest = '@/CM_CIT_BAL_UPDATE_ACCOUNTS_REQUEST',
  CitBalUpdateAccountsFail = '@/CM_CIT_BAL_UPDATE_ACCOUNTS_FAIL',

  CitBalGetVaultTransactionTotalsRequest = '@/CM_CIT_BAL_GET_VAULT_TRANSACTION_TOTALS_REQUEST',
  CitBalGetVaultTransactionTotalsResponse = '@/CM_CIT_BAL_GET_VAULT_TRANSACTION_TOTALS_RESPONSE',
  CitBalGetVaultTransactionTotalsFail = '@/CM_CIT_BAL_GET_VAULT_TRANSACTION_TOTALS_FAIL',

  CitBalSetVaultTransactionRequest = '@/CM_CIT_BAL_SET_VAULT_TRANSACTION_REQUEST',

  CitBalRevertTransactionRequest = '@/CM_CIT_BAL_REVERT_TRANSACTION_REQUEST',

  CitBalGetReportsRequest = '@/CM_CIT_BAL_GET_REPORTS_REQUEST',
  CitBalGetReportsResponse = '@/CM_CIT_BAL_GET_REPORTS_RESPONSE',
  CitBalGetReportsFail = '@/CM_CIT_BAL_GET_REPORTS_FAIL',

  CitBalGetReportRequest = '@/CM_CIT_BAL_GET_REPORT_REQUEST',
  CitBalGetReportResponse = '@/CM_CIT_BAL_GET_REPORT_RESPONSE',
  CitBalGetReportFail = '@/CM_CIT_BAL_GET_REPORT_FAIL',

  CitBalSetInitialReports = '@/CM_CIT_BAL_SET_INITIAL_REPORTS',

  CitBalGetDialogReportsRequest = '@/CM_BAL_GET_DIALOG_REPORTS_REQUEST',
  CitBalGetDialogReportsResponse = '@/CM_BAL_GET_DIALOG_REPORTS_RESPONSE',
  CitBalGetDialogReportsFail = '@/CM_BAL_GET_DIALOG_REPORTS_FAIL',

  CitBalGetDialogReportRequest = '@/CM_BAL_GET_DIALOG_REPORT_REQUEST',
  CitBalGetDialogReportResponse = '@/CM_BAL_GET_DIALOG_REPORT_RESPONSE',
  CitBalGetDialogReportFail = '@/CM_BAL_GET_DIALOG_REPORT_FAIL',

  CitBalFetchAssetItemReportRequest = '@/CM_BAL_FETCH_ASSET_ITEM_REPORT_REQUEST',
  CitBalFetchAssetItemReportResponse = '@/CM_BAL_FETCH_ASSET_ITEM_REPORT_RESPONSE',
  CitBalFetchAssetItemReportFail = '@/CM_BAL_FETCH_ASSET_ITEM_REPORT_FAIL',
  CitBalSetIsZip = '@/CM_BAL_SET_IS_ZIP',

  CitBlnTransactionButtonStatusRequest = '@/CM_BAL_GET_TRANSACTION_BUTTON_STATUS_REQUEST',
  CitBlnTransactionButtonStatusResponse = '@/CM_BAL_GET_TRANSACTION_BUTTON_STATUS_RESPONSE',
  CitBlnTransactionButtonStatusFail = '@/CM_BAL_GET_TRANSACTION_BUTTON_STATUS_FAIL',

  CitBlnSetMixedOperation = '@/CM_BAL_SET_MIXED_OPERATION',

  CitBalGetAddEventsRequest = '@/CM_BAL_GET_ADD_EVENTS_REQUEST',
  CitBalGetAddEventsResponse = '@/CM_BAL_GET_ADD_EVENTS_RESPONSE',
  CitBalGetAddEventsFail = '@/CM_BAL_GET_ADD_EVENTS_FAIl',

  CitBalCalcAddEventsTotalRequest = '@/CIT_BAL_CALC_ADD_EVENTS_TOTAL_REQUEST',
  CitBalCalcAddEventsTotalResponse = '@/CIT_BAL_CALC_ADD_EVENTS_TOTAL_RESPONSE',
  CitBalCalcAddEventsTotalFail = '@/CIT_BAL_CALC_ADD_EVENTS_TOTAL_FAIL',

  CitBalUpdateAddEvents = '@/CM_BAL_UPDATE_ADD_EVENTS',

  CitBalCreateAddEventRequest = '@/CM_BAL_CREATE_ADD_EVENT_REQUEST',
  CitBalCreateAddEventResponse = '@/CM_BAL_CREATE_ADD_EVENT_RESPONSE',
  CitBalCreateAddEventFail = '@/CM_BAL_CREATE_ADD_EVENT_FAIL',

  CitBalAssignCashierRequest = '@/CM_CIT_BAL_ASSIGN_CASHIER_REQUEST',
  CitBalAssignCashierResponse = '@/CM_CIT_BAL_ASSIGN_CASHIER_RESPONSE',

  CitBalGetCashierRequest = '@/CM_CIT_BAL_GET_CASHIER_REQUEST',
  CitBalGetCashierResponse = '@/CM_CIT_BAL_GET_CASHIER_RESPONSE',

  CitBalModifyCassettesRequest = '@/CM_CIT_BAL_MODIFY_CASSETTES_REQUEST',
  CitBalModifyCassettesResponse = '@/CM_CIT_BAL_MODIFY_CASSETTES_RESPONSE',
  CitBalModifyCassettesFail = '@/CM_CIT_BAL_MODIFY_CASSETTES_FAIL',

  CitBalRemoveCassetteRequest = '@/CM_CIT_BAL_REMOVE_CASSETTE_REQUEST',
  CitBalRemoveCassetteResponse = '@/CM_CIT_BAL_REMOVE_CASSETTE_RESPONSE',
  CitBalRemoveCassetteFail = '@/CM_CIT_BAL_REMOVE_CASSETTE_FAIL',

  CitBalHandleKeyPress = 'CIT_BAL_HANDLE_KEY_PRESS',

  CitBalSetUnloadedCassettesApplied = '@/CM_CIT_BAL_SET_UNLOADED_CASSETTES_APPLIED',

  // Plastic Card
  PlcGetPlasticCardsRequest = '@/CM_PLC_GET_PLASTIC_CARDS_REQUEST',
  PlcGetPlasticCardsResponse = '@/CM_PLC_GET_PLASTIC_CARDS_RESPONSE',
  PlcGetPlasticCardsFail = '@/CM_PLC_GET_PLASTIC_CARDS_FAIL',

  PlcGetSinglePlasticCardRequest = '@/CM_PLC_GET_SINGLE_PLASTIC_CARD_REQUEST',
  PlcGetSinglePlasticCardResponse = '@/CM_PLC_GET_SINGLE_PLASTIC_CARD_RESPONSE',
  PlcGetSinglePlasticCardFail = '@/CM_PLC_GET_SINGLE_PLASTIC_CARD_FAIL',

  PlcSetPlasticCardFilter = '@/CM_PLC_SET_PLASTIC_CARD_FILTER',
  PlcSubmitPlasticCardFilter = '@/CM_PLC_SUBMIT_PLASTIC_CARD_FILTER',
  PlcSetPlasticCardPaging = '@/CM_PLC_SET_PLASTIC_CARD_PAGING',
  PlcSetPlasticCardSort = '@/CM_PLC_SET_PLASTIC_CARD_SORT',
  PlcResetPaging = '@/CM_PLC_RESET_PAGING',
  PlcSetSelectedPlasticCard = '@/CM_PLC_SET_SELECTED_PLASTIC_CARD',

  PlcCheckPlasticCard = '@/CM_PLC_CHECK_PLASTIC_CARD',
  PlcCheckAllPlasticCard = '@/CM_PLC_CHECK_ALL_PLASTIC_CARD',
  PlcClearAllPlasticCard = '@/CM_PLC_CLEAR_ALL_PLASTIC_CARD',

  PlcOpenSettingsModal = '@/CM_PLC_OPEN_SETTINGS_MODAL',
  PlcOpenFilterModal = '@/CM_PLC_OPEN_FILTER_MODAL',

  PlcHandleKeyPress = '@/CM_PLC_HANDLE_KEY_PRESS',

  // Teams
  PlcSetTeamRequest = '@/CM_PLC_SET_TEAM_REQUEST',
  PlcSetTeamFail = '@/CM_PLC_SET_TEAM_FAIL',

  PlcResetTeamRequest = '@/CM_PLC_RESET_TEAM_REQUEST',
  PlcResetTeamFail = '@/CM_PLC_RESET_TEAM_FAIL',

  // Work order
  PlcGetWorkOrdersRequest = '@/CM_PLC_GET_WORK_ORDERS_REQUEST',
  PlcGetWorkOrdersResponse = '@/CM_PLC_GET_WORK_ORDERS_RESPONSE',
  PlcGetWorkOrdersFail = '@/CM_PLC_GET_WORK_ORDERS_FAIL',

  PlcCreateWorkOrderRequest = '@/CM_PLC_CREATE_WORK_ORDER_REQUEST',
  PlcCreateWorkOrderResponse = '@/CM_PLC_CREATE_WORK_ORDER_RESPONSE',
  PlcCreateWorkOrderFail = '@/CM_PLC_CREATE_WORK_ORDER_FAIL',

  PlcDeclineWorkOrderRequest = '@/CM_PLC_DECLINE_WORK_ORDER_REQUEST',
  PlcDeclineWorkOrderResponse = '@/CM_PLC_DECLINE_WORK_ORDER_RESPONSE',
  PlcDeclineWorkOrderFail = '@/CM_PLC_DECLINE_WORK_ORDER_FAIL',

  PlcCompleteWorkOrderRequest = '@/CM_PLC_COMPLETE_WORK_ORDER_REQUEST',
  PlcCompleteWorkOrderResponse = '@/CM_PLC_COMPLETE_WORK_ORDER_RESPONSE',
  PlcCompleteWorkOrderFail = '@/CM_PLC_COMPLETE_WORK_ORDER_FAIL',

  PlcDeleteWorkOrderRequest = '@/CM_PLC_DELETE_WORK_ORDER_REQUEST',
  PlcDeleteWorkOrderResponse = '@/CM_PLC_DELETE_WORK_ORDER_RESPONSE',
  PlcDeleteWorkOrderFail = '@/CM_PLC_DELETE_WORK_ORDER_FAIL',

  PlcSetWorkOrdersFilter = '@/CM_PLC_SET_WORK_ORDERS_FILTER',
  PlcSetSelectedWorkOrder = '@/CM_PLC_SET_SELECTED_WORK_ORDER',
  PlcSetWorkOrderSort = '@/CM_PLC_SET_WORK_ORDER_SORT',

  GetPlcWorkOrderReportsRequest = '@/CM_PLC_GET_WORK_ORDER_REPORTS_REQUEST',
  GetPlcWorkOrderReportsResponse = '@/CM_PLC_GET_WORK_ORDER_REPORTS_RESPONSE',
  GetPlcWorkOrderReportsFail = '@/CM_PLC_GET_WORK_ORDER_REPORTS_FAIL',
  GetPlcWorkOrderReportRequest = '@/CM_PLC_GET_WORK_ORDER_REPORT_REQUEST',
  GetPlcWorkOrderReportResponse = '@/CM_PLC_GET_WORK_ORDER_REPORT_RESPONSE',
  GetPlcWorkOrderReportFail = '@/CM_PLC_GET_WORK_ORDER_REPORT_FAIL',

  // Teams
  GetPlcTeamsRequest = '@/CM_PLC_GET_TEAMS_REQUEST',
  GetPlcTeamsResponse = '@/CM_PLC_GET_TEAMS_RESPONSE',
}

export enum CMUsersAndRolesActions {
  // Common ==============================================
  AdmSetSelectedUsersAndRolesPanel = '@/CM_ADM_SET_SELECTED_USERS_AND_ROLES_PANEL',

  // Users ===============================================
  AdmSortUsers = '@/CM_ADM_SORT_USERS',

  AdmGetUsersRequest = '@/CM_ADM_GET_USERS_REQUEST',
  AdmGetUsersResponse = '@/CM_ADM_GET_USERS_RESPONSE',
  AdmGetUsersFail = '@/CM_ADM_GET_USERS_FAIL',

  AdmAddUserRequest = '@/CM_ADM_ADD_USER_REQUEST',
  AdmAddUserFail = '@/CM_ADM_ADD_USER_FAIL',

  AdmUpdateUserRequest = '@/CM_ADM_UPDATE_USER_REQUEST',
  AdmUpdateUserFail = '@/CM_ADM_UPDATE_USER_FAIL',

  AdmDeleteUserRequest = '@/CM_ADM_DELETE_USER_REQUEST',
  AdmDeleteUserFail = '@/CM_ADM_DELETE_USER_FAIL',

  AdmSetSelectedUser = '@/CM_ADM_SET_SELECTED_USER',

  AdmApplyUserFilterRequest = '@/CM_ADM_APPLY_USER_FILTER_REQUEST',

  AdmGetBadgeReportsRequest = '@/CM_ADM_GET_USER_BADGE_REPORTS_REQUEST',
  AdmGetBadgeReportsResponse = '@/CM_ADM_GET_USER_BADGE_REPORTS_RESPONSE',
  AdmGetBadgeReportsFail = '@/CM_ADM_GET_USER_BADGE_REPORTS_FAIL',

  AdmGetBadgeReportRequest = '@/CM_ADM_GET_USER_BADGE_REPORT_REQUEST',
  AdmGetBadgeReportResponse = '@/CM_ADM_GET_USER_BADGE_REPORT_RESPONSE',
  AdmGetBadgeReportFail = '@/CM_ADM_GET_USER_BADGE_REPORT_FAIL',

  // Roles ====================================================
  AdmSortRoles = '@/CM_ADM_SORT_ROLES',

  AdmGetRolesRequest = '@/CM_ADM_GET_ROLES_REQUEST',
  AdmGetRolesResponse = '@/CM_ADM_GET_ROLES_RESPONSE',
  AdmGetRolesFail = '@/CM_ADM_GET_ROLES_FAIl',

  AdmAddRoleRequest = '@/CM_ADM_ADD_ROLE_REQUEST',
  AdmAddRoleFail = '@/CM_ADM_ADD_ROLE_FAIL',

  AdmUpdateRoleRequest = '@/CM_ADM_UPDATE_ROLE_REQUEST',
  AdmUpdateRoleFail = '@/CM_ADM_UPDATE_ROLE_FAIL',

  AdmDeleteRoleRequest = '@/CM_ADM_DELETE_ROLE_REQUEST',
  AdmDeleteRoleFail = '@/CM_ADM_DELETE_ROLE_FAIL',

  AdmGetBaseRolesRequest = '@/CM_ADM_GET_BASE_ROLES_REQUEST',
  AdmGetBaseRolesResponse = '@/CM_ADM_GET_BASE_ROLES_RESPONSE',

  AdmGetUsersByRoleRequest = '@/CM_ADM_GET_USERS_BY_ROLE_REQUEST',
  AdmGetUsersByRoleResponse = '@/CM_ADM_GET_USERS_BY_ROLE_RESPONSE',
  AdmGetUsersByRoleFail = '@/CM_ADM_GET_USERS_BY_ROLE_FAIL',

  AdmSetSelectedRole = '@/CM_ADM_SET_SELECTED_ROLE',

  AdmUsersAssignRoleRequest = '@/CM_ADM_USERS_ASSIGN_ROLE_REQUEST',
  AdmUsersAssignRoleFail = '@/CM_ADM_USERS_ASSIGN_ROLE_FAIL',

  // Companies ====================================================
  AdmGetCompaniesRequest = '@/CM_ADM_GET_COMPANIES_REQUEST',
  AdmGetCompaniesResponse = '@/CM_ADM_GET_COMPANIES_RESPONSE',
  AdmGetCompaniesFail = '@/CM_ADM_GET_COMPANIES_FAIL',

  AdmAddCompanyRequest = '@/CM_ADM_ADD_COMPANY_REQUEST',
  AdmAddCompanyFail = '@/CM_ADM_ADD_COMPANY_FAIL',

  AdmUpdateCompanyRequest = '@/CM_ADM_UPDATE_COMPANY_REQUEST',
  AdmUpdateCompanyFail = '@/CM_ADM_UPDATE_COMPANY_FAIL',

  AdmDeleteCompanyRequest = '@/CM_ADM_DELETE_COMPANY_REQUEST',
  AdmDeleteCompanyFail = '@/CM_ADM_DELETE_COMPANY_FAIL',

  AdmSetSelectedCompany = '@/CM_ADM_SET_SELECTED_COMPANY',
  AdmSortCompanies = '@/CM_ADM_SORT_COMPANIES',

  // Staff ===========================================================
  AdmSortStaff = '@/CM_ADM_SORT_STAFF',
  AdmGetStaffRequest = '@/CM_ADM_GET_STAFF_REQUEST',
  AdmGetStaffResponse = '@/CM_ADM_GET_STAFF_RESPONSE',
  AdmGetStaffFail = '@/CM_ADM_GET_STAFF_FAIL',

  AdmEditStaffRequest = '@/CM_ADM_EDIT_STAFF_REQUEST',
  AdmEditStaffFail = '@/CM_ADM_EDIT_STAFF_FAIL',

  AdmGetStaffManagersRequest = '@/CM_ADM_GET_STAFF_MANAGERS_REQUEST',
  AdmGetStaffManagersResponse = '@/CM_ADM_GET_STAFF_MANAGERS_RESPONSE',
  AdmGetStaffManagersFail = '@/CM_ADM_GET_STAFF_MANAGERS_FAIL',

  AdmSetSelectedStaffManager = '@/CM_ADM_SET_SELECTED_STAFF_MANAGER',
  AdmSortStaffManagers = '@/CM_ADM_SORT_STAFF_MANAGERS',
}

export enum CMAccessRulesActions {
  // Common ==============================================
  AdmSetSelectedAccessRulesPanel = '@/CM_ADM_SET_SELECTED_ACCESS_RULES_PANEL',

  // Roles Permissions ==============================================
  AdmGetAccessRulesRequest = '@/CM_ADM_GET_ACCESS_RULES_REQUEST',
  AdmGetAccessRulesResponse = '@/CM_ADM_GET_ACCESS_RULES_RESPONSE',
  AdmGetAccessRulesFail = '@/CM_ADM_GET_ACCESS_RULES_FAIL',

  AdmSetSortAccessRules = '@/CM_ADM_SET_SORT_ACCESS_RULES',
  AdmSetSelectedAccessRule = '@/CM_ADM_SET_SELECTED_ACCESS_RULE',

  AdmGetAccessRulesModulesRequest = '@/CM_ADM_GET_ACCESS_RULES_MODULES_REQUEST',
  AdmGetAccessRulesModulesResponse = '@/CM_ADM_GET_ACCESS_RULES_MODULES_RESPONSE',
  AdmGetAccessRulesModulesFail = '@/CM_ADM_GET_ACCESS_RULES_MODULES_FAIL',

  AdmSetAccessRulesModulesRequest = '@/CM_ADM_SET_ACCESS_RULES_MODULES_REQUEST',
  AdmSetAccessRulesModulesFail = '@/CM_ADM_SET_ACCESS_RULES_MODULES_FAIL',

  AdmGetAccessRulesPermissionsRequest = '@/CM_ADM_GET_ACCESS_RULES_PERMISSIONS_REQUEST',
  AdmGetAccessRulesPermissionsResponse = '@/CM_ADM_GET_ACCESS_RULES_PERMISSIONS_RESPONSE',
  AdmGetAccessRulesPermissionsFail = '@/CM_ADM_GET_ACCESS_RULES_PERMISSIONS_FAIL',

  AdmSetAccessRulesPermissionsRequest = '@/CM_ADM_SET_ACCESS_RULES_PERMISSIONS_REQUEST',
  AdmSetAccessRulesPermissionsFail = '@/CM_ADM_SET_ACCESS_RULES_PERMISSIONS_FAIL',

  AdmGetAccessRulesChartsRequest = '@/CM_ADM_GET_ACCESS_RULES_CHARTS_REQUEST',
  AdmGetAccessRulesChartsResponse = '@/CM_ADM_GET_ACCESS_RULES_CHARTS_RESPONSE',
  AdmGetAccessRulesChartsFail = '@/CM_ADM_GET_ACCESS_RULES_CHARTS_FAIL',

  AdmSetAccessRulesChartsRequest = '@/CM_ADM_SET_ACCESS_RULES_CHARTS_REQUEST',
  AdmSetAccessRulesChartsFail = '@/CM_ADM_SET_ACCESS_RULES_CHARTS_FAIL',

  // Approval Changes ==============================================
  AdmGetApprovalChangesFormRequest = '@/CM_ADM_GET_APPROVAL_CHANGES_FORM_REQUEST',
  AdmGetApprovalChangesFormResponse = '@/CM_ADM_GET_APPROVAL_CHANGES_FORM_RESPONSE',
  AdmGetApprovalChangesFormFail = '@/CM_ADM_GET_APPROVAL_CHANGES_FORM_FAIL',

  AdmSetApprovalChangesFormRequest = '@/CM_ADM_SET_APPROVAL_CHANGES_FORM_REQUEST',
  AdmSetApprovalChangesFormFail = '@/CM_ADM_SET_APPROVAL_CHANGES_FORM_FAIL',

  AdmSetApprovalChangesSelectedForm = '@/CM_ADM_SET_APPROVAL_CHANGES_SELECTED_FORM',

  AdmGetApprovalChangesRolesRequest = '@/CM_ADM_GET_APPROVAL_CHANGES_ROLES_REQUEST',
  AdmGetApprovalChangesRolesResponse = '@/CM_ADM_GET_APPROVAL_CHANGES_ROLES_RESPONSE',
  AdmGetApprovalChangesRolesFail = '@/CM_ADM_GET_APPROVAL_CHANGES_ROLES_FAIL',

  AdmSetApprovalChangesRolesRequest = '@/CM_ADM_SET_APPROVAL_CHANGES_ROLES_REQUEST',
  AdmSetApprovalChangesRolesResponse = '@/CM_ADM_SET_APPROVAL_CHANGES_ROLES_RESPONSE',
  AdmSetApprovalChangesRolesFail = '@/CM_ADM_SET_APPROVAL_CHANGES_ROLES_FAIL',

  AdmSetCheckedApprovalRoles = '@/CM_ADM_SET_CHECKED_APPROVAL_ROLES',
  AdmSetChangedApprovalRole = '@/CM_ADM_SET_CHANGED_APPROVAL_ROLE',
  AdmRefreshApprovalChangesRoles = '@/CM_ADM_REFRESH_APPROVAL_CHANGES_ROLES',

  // User Activity ==============================================
  AdmSetUserActivityFilter = '@/CM_ADM_SET_USER_ACTIVITY_FILTER',
  AdmSetUserActivitySort = '@/CM_ADM_SET_USER_ACTIVITY_SORT',
  AdmSetUserActivityPaging = '@/CM_ADM_SET_USER_ACTIVITY_PAGING',

  AdmGetUserActivityRequest = '@/CM_ADM_GET_USER_ACTIVITY_REQUEST',
  AdmGetUserActivityResponse = '@/CM_ADM_GET_USER_ACTIVITY_RESPONSE',
  AdmGetUserActivityFail = '@/CM_ADM_GET_USER_ACTIVITY_FAIL',

  AdmUserActivityGetReportsRequest = '@/CM_ADM_GET_USER_ACTIVITY_REPORTS_REQUEST',
  AdmUserActivityGetReportsResponse = '@/CM_ADM_GET_USER_ACTIVITY_REPORTS_RESPONSE',
  AdmUserActivityGetReportsFail = '@/CM_ADM_GET_USER_ACTIVITY_REPORTS_FAIL',

  AdmUserActivityGetReportRequest = '@/CM_ADM_GET_USER_ACTIVITY_REPORT_REQUEST',
  AdmUserActivityGetReportResponse = '@/CM_ADM_GET_USER_ACTIVITY_REPORT_RESPONSE',
  AdmUserActivityGetReportFail = '@/CM_ADM_GET_USER_ACTIVITY_REPORT_FAIL',
}

export enum CMAppConfigActions {
  // Common ==============================================
  AdmSetSelectedAppConfigPanel = '@/CM_ADM_SET_SELECTED_APP_CONFIG_PANEL',

  // Upload Logo ==============================================
  AdmGetLogoRequest = '@/CM_ADM_GET_LOGO_REQUEST',
  AdmGetLogoResponse = '@/CM_ADM_GET_LOGO_RESPONSE',
  AdmGetLogoFail = '@/CM_ADM_GET_LOGO_FAIL',

  AdmGetApprovingLogoRequest = '@/CM_ADM_GET_APPROVING_LOGO_REQUEST',
  AdmGetApprovingLogoResponse = '@/CM_ADM_GET_APPROVING_LOGO_RESPONSE',
  AdmGetApprovingLogoFail = '@/CM_ADM_GET_APPROVING_LOGO_FAIL',

  AdmSetApprovingLogoRequest = '@/CM_ADM_SET_APPROVING_LOGO_REQUEST',

  // Real time monitoring ==============================================
  AdmGetRealTimeMonitoringRequest = '@/CM_ADM_GET_REAL_TIME_MONITORING_REQUEST',
  AdmGetRealTimeMonitoringResponse = '@/CM_ADM_GET_REAL_TIME_MONITORING_RESPONSE',
  AdmGetRealTimeMonitoringFail = '@/CM_ADM_GET_REAL_TIME_MONITORING_FAIL',

  AdmSaveRealTimeMonitoringRequest = '@/CM_ADM_SAVE_REAL_TIME_MONITORING_REQUEST',

  // Rounding Settings ==============================================
  AdmGetRoundingSettingsRequest = '@/CM_ADM_GET_ROUNDING_SETTINGS_REQUEST',
  AdmGetRoundingSettingsResponse = '@/CM_ADM_GET_ROUNDING_SETTINGS_RESPONSE',
  AdmGetRoundingSettingsFail = '@/CM_ADM_GET_ROUNDING_SETTINGS_FAIL',

  AdmUpdateRoundingSettingsRequest = '@/CM_ADM_UPDATE_ROUNDING_SETTINGS_REQUEST',
  AdmUpdateRoundingSettingsFail = '@/CM_ADM_UPDATE_ROUNDING_SETTINGS_FAIL',

  // EventNotifications ==============================================
  AdmEventNotificationsRequest = '@/CM_ADM_EVENT_NOTIFICATIONS_REQUEST',
  AdmEventNotificationsResponse = '@/CM_ADM_EVENT_NOTIFICATIONS_RESPONSE',
  AdmEventNotificationsFail = '@/CM_ADM_EVENT_NOTIFICATIONS_FAIL',

  AdmEventNotificationsUpdateRequest = '@/CM_ADM_EVENT_NOTIFICATION_UPDATE_REQUEST',
  AdmEventNotificationsUpdateFail = '@/CM_ADM_EVENT_NOTIFICATION_UPDATE_FAIL',

  AdmEventNotificationsAddRequest = '@/CM_ADM_EVENT_NOTIFICATION_ADD_REQUEST',
  AdmEventNotificationsAddFail = '@/CM_ADM_EVENT_NOTIFICATION_ADD_FAIL',

  AdmEventNotificationsDeleteRequest = '@/CM_ADM_EVENT_NOTIFICATION_DELETE_REQUEST',
  AdmEventNotificationsDeleteFail = '@/CM_ADM_EVENT_NOTIFICATION_DELETE_FAIL',

  AdmSortEventNotifications = '@/CM_ADM_SORT_EVENT_NOTIFICATION',

  // Automated Operations ==============================================
  AdmGetAutoOperationsRequest = '@/CM_ADM_GET_AUTO_OPERATIONS_REQUEST',
  AdmGetAutoOperationsResponse = '@/CM_ADM_GET_AUTO_OPERATIONS_RESPONSE',
  AdmGetAutoOperationsFail = '@/CM_ADM_GET_AUTO_OPERATIONS_FAIL',

  AdmUpdateAutoOperationsRequest = '@/CM_ADM_UPDATE_AUTO_OPERATIONS_REQUEST',
  AdmUpdateAutoOperationsFail = '@/CM_ADM_UPDATE_AUTO_OPERATIONS_FAIL',

  // Banks ==============================================
  AdmGetBanksRequest = '@/CM_ADM_GET_BANKS_REQUEST',
  AdmGetBanksResponse = '@/CM_ADM_GET_BANKS_RESPONSE',
  AdmGetBanksFail = '@/CM_ADM_GET_BANKS_FAIL',

  AdmUpdateBankRequest = '@/CM_ADM_UPDATE_BANK_REQUEST',
  AdmUpdateBankFail = '@/CM_ADM_UPDATE_BANK_FAIL',

  AdmDeleteBankRequest = '@/CM_ADM_DELETE_BANK_REQUEST',
  AdmDeleteBankFail = '@/CM_ADM_DELETE_BANK_FAIL',

  AdmAddNewBankRequest = '@/CM_ADM_ADD_NEW_BANK_REQUEST',
  AdmAddNewBankFail = '@/CM_ADM_ADD_NEW_BANK_FAIL',

  AdmSetSelectedBank = '@/CM_ADM_SET_SELECTED_BANK',
  AdmSortBanks = '@/CM_ADM_SORT_BANKS',

  // Cassette Types ==========================================
  AdmGetCassetteTypesRequest = '@/CM_ADM_GET_CASSETTE_TYPES_REQUEST',
  AdmGetCassetteTypesResponse = '@/CM_ADM_GET_CASSETTE_TYPES_RESPONSE',
  AdmGetCassetteTypesFail = '@/CM_ADM_GET_CASSETTE_TYPES_FAIL',

  AdmSortCassetteTypes = '@/CM_ADM_SORT_CASSETTE_TYPES',
  AdmSetSelectedCassetteType = '@/CM_ADM_SET_SELECTED_CASSETTE_TYPE',

  AdmDeleteCassetteTypeRequest = '@/CM_ADM_DELETE_CASSETTE_TYPE_REQUEST',
  AdmDeleteCassetteTypeResponse = '@/CM_ADM_DELETE_CASSETTE_TYPE_RESPONSE',
  AdmDeleteCassetteTypeFail = '@/CM_ADM_DELETE_CASSETTE_TYPE_FAIL',

  AdmAddCassetteTypeRequest = '@/CM_ADM_ADD_CASSETTE_TYPE_REQUEST',
  AdmAddCassetteTypeFail = '@/CM_ADM_ADD_CASSETTE_TYPE_FAIl',

  AdmUpdateCassetteTypeRequest = '@/CM_ADM_UPDATE_CASSETTE_TYPE_REQUEST',
  AdmUpdateCassetteTypeFail = '@/CM_ADM_UPDATE_CASSETTE_TYPE_FAIl',

  // Currencies ==========================================
  AdmSortCurrencies = '@/CM_ADM_SORT_CURRENCIES',

  AdmGetCurrenciesRequest = '@/CM_ADM_GET_CURRENCIES_REQUEST',
  AdmGetCurrenciesResponse = '@/CM_ADM_GET_CURRENCIES_RESPONSE',
  AdmGetCurrenciesFail = '@/CM_ADM_GET_CURRENCIES_FAIL',

  AdmAddCurrencyRequest = '@/CM_ADM_ADD_CURRENCY_REQUEST',
  AdmAddCurrencyFail = '@/CM_ADM_ADD_CURRENCY_FAIL',

  AdmUpdateCurrencyRequest = '@/CM_ADM_UPDATE_CURRENCY_REQUEST',
  AdmUpdateCurrencyFail = '@/CM_ADM_UPDATE_CURRENCY_FAIL',

  AdmDeleteCurrencyRequest = '@/CM_ADM_DELETE_CURRENCY_REQUEST',
  AdmDeleteCurrencyFail = '@/CM_ADM_DELETE_CURRENCY_FAIL',

  AdmSetCurrencyAsBaseRequest = 'CM_ADM_SET_CURRENCY_AS_BASE_REQUEST',
  AdmSetCurrencyAsBaseFail = 'CM_ADM_SET_CURRENCY_AS_BASE_FAIL',

  // Forecast =========================================================
  AdmGetForecastSettingsRequest = '@/CM_ADM_GET_FORECAST_SETTINGS_REQUEST',
  AdmGetForecastSettingsResponse = '@/CM_ADM_GET_FORECAST_SETTINGS_RESPONSE',
  AdmGetForecastSettingsFail = '@/CM_ADM_GET_FORECAST_SETTINGS_FAIL',

  AdmSaveForecastSettingsRequest = '@/CM_ADM_SAVE_FORECAST_SETTINGS_REQUEST',
  AdmSaveForecastSettingsFail = '@/CM_ADM_SAVE_FORECAST_SETTINGS_FAIL',

  // App Config ====================================================
  AdmGetApplicationConfigRequest = '@/CM_ADM_GET_APPLICATION_CONFIG_REQUEST',
  AdmGetApplicationConfigResponse = '@/CM_ADM_GET_APPLICATION_CONFIG_RESPONSE',
  AdmGetApplicationConfigFail = '@/CM_ADM_GET_APPLICATION_CONFIG_FAIL',

  AdmUpdateApplicationConfigRequest = '@/CM_ADM_UPDATE_APPLICATION_CONFIG_REQUEST',
  AdmUpdateApplicationConfigFail = '@/CM_ADM_UPDATE_APPLICATION_CONFIG_FAIL',
}

export enum CMAdmCitActions {
  // Common ==============================================
  AdmSetSelectedCitPanel = '@/CM_ADM_SET_SELECTED_CIT_PANEL',

  // Employees ===========================================
  AdmGetEmployeesRequest = '@/CM_ADM_GET_EMPLOYEES_REQUEST',
  AdmGetEmployeesResponse = '@/CM_ADM_GET_EMPLOYEES_RESPONSE',
  AdmGetEmployeesFail = '@/CM_ADM_GET_EMPLOYEES_FAIL',

  AdmSetSelectedEmployeesCompany = '@/CM_ADM_SET_SELECTED_EMPLOYEES_COMPANY',
  AdmSetSelectedEmployee = '@/CM_ADM_SET_SELECTED_EMPLOYEE',
  AdmSortEmployees = '@/CM_ADM_SORT_EMPLOYEES',

  AdmUpdateEmployeeRequest = '@/CM_ADM_UPDATE_EMPLOYEE_REQUEST',
  AdmUpdateEmployeeFail = '@/CM_ADM_UPDATE_EMPLOYEE_FAIL',

  // Vehicle ==============================================
  AdmGetVehiclesRequest = '@/CM_ADM_GET_VEHICLES_REQUEST',
  AdmGetVehiclesResponse = '@/CM_ADM_GET_VEHICLES_RESPONSE',
  AdmGetVehiclesFail = '@/CM_ADM_GET_VEHICLES_FAIL',

  AdmAddVehicleRequest = '@/CM_ADM_ADD_VEHICLE_REQUEST',
  AdmAddVehicleFail = '@/CM_ADM_ADD_VEHICLE_FAIL',

  AdmUpdateVehicleRequest = '@/CM_ADM_UPDATE_VEHICLE_REQUEST',
  AdmUpdateVehicleFail = '@/CM_ADM_UPDATE_VEHICLE_FAIL',

  AdmDeleteVehicleRequest = '@/CM_ADM_DELETE_VEHICLE_REQUEST',
  AdmDeleteVehicleFail = '@/CM_ADM_DELETE_VEHICLE_FAIL',

  AdmSetSelectedVehicleCompany = '@/CM_ADM_SET_SELECTED_VEHICLE_COMPANY_REQUEST',

  AdmSortVehicles = '@/CM_ADM_SORT_VEHICLES',

  // Vehicle Types ==============================================
  AdmGetVehicleTypesRequest = '@/CM_ADM_GET_VEHICLE_TYPES_REQUEST',
  AdmGetVehicleTypesResponse = '@/CM_ADM_GET_VEHICLE_TYPES_RESPONSE',
  AdmGetVehicleTypesFail = '@/CM_ADM_GET_VEHICLE_TYPES_FAIL',

  AdmAddVehicleTypeRequest = '@/CM_ADM_ADD_VEHICLE_TYPE_REQUEST',
  AdmAddVehicleTypeFail = '@/CM_ADM_ADD_VEHICLE_TYPE_FAIL',

  AdmUpdateVehicleTypeRequest = '@/CM_ADM_UPDATE_VEHICLE_TYPE_REQUEST',
  AdmUpdateVehicleTypeFail = '@/CM_ADM_UPDATE_VEHICLE_TYPE_FAIL',

  AdmDeleteVehicleTypeRequest = '@/CM_ADM_DELETE_VEHICLE_TYPE_REQUEST',
  AdmDeleteVehicleTypeFail = '@/CM_ADM_DELETE_VEHICLE_TYPE_FAIL',

  AdmSetSelectedVehicleType = '@/CM_ADM_SET_SELECTED_VEHICLE_TYPE',

  // Fuel Types ====================================================
  AdmGetFuelTypesRequest = '@/CM_ADM_GET_FUEL_TYPES_REQUEST',
  AdmGetFuelTypesResponse = '@/CM_ADM_GET_FUEL_TYPES_RESPONSE',
  AdmGetFuelTypesFail = '@/CM_ADM_GET_FUEL_TYPES_FAIL',

  AdmAddFuelTypeRequest = '@/CM_ADM_ADD_FUEL_TYPE_REQUEST',
  AdmAddFuelTypeFail = '@/CM_ADM_ADD_FUEL_TYPE_FAIL',

  AdmUpdateFuelTypeRequest = '@/CM_ADM_UPDATE_FUEL_TYPE_REQUEST',
  AdmUpdateFuelTypeFail = '@/CM_ADM_UPDATE_FUEL_TYPE_FAIL',

  AdmDeleteFuelTypeRequest = '@/CM_ADM_DELETE_FUEL_TYPE_REQUEST',
  AdmDeleteFuelTypeFail = '@/CM_ADM_DELETE_FUEL_TYPE_FAIL',

  AdmSetSelectedFuelType = '@/CM_ADM_SET_SELECTED_FUEL_TYPE',

  // Teams ===========================================================
  AdmGetTeamsRequest = '@/CM_ADM_GET_TEAMS_REQUEST',
  AdmGetTeamsResponse = '@/CM_ADM_GET_TEAMS_RESPONSE',
  AdmGetTeamsFail = '@/CM_ADM_GET_TEAMS_FAIL',

  AdmDeleteTeamRequest = '@/CM_ADM_DELETE_TEAM_REQUEST',
  AdmDeleteTeamFail = '@/CM_ADM_DELETE_TEAM_FAIL',

  AdmSetSelectedTeam = '@/CM_ADM_SET_SELECTED_TEAM',

  AdmSortTeamsTable = 'CM_ADM_SORT_TEAMS_TABLE',

  // Routes ===========================================================
  AdmRefreshRoutes = '@/CM_ADM_REFRESH_ROUTES',

  CitSortRoutes = '@/CM_CIT_ADM_SORT_ROUTES',
  AdmSortRoutes = '@/CM_ADM_SORT_ROUTES',

  CitSortRouteObjects = '@/CM_CIT_SORT_ROUTE_OBJECTS',
  AdmSortRouteObjects = '@/CM_ADM_SORT_ROUTE_OBJECTS',

  CitGetRoutesObjectsRequest = '@/CM_ADM_CIT_GET_ROUTES_OBJECTS_REQUEST',
  CitGetRoutesObjectsResponse = '@/CM_ADM_CIT_GET_ROUTES_OBJECTS_RESPONSE',
  CitGetRoutesObjectsFail = '@/CM_ADM_CIT_GET_ROUTES_OBJECTS_FAIL',

  AdmGetRoutesObjectsRequest = '@/CM_ADM_GET_ROUTES_OBJECTS_REQUEST',
  AdmGetRoutesObjectsResponse = '@/CM_ADM_GET_ROUTES_OBJECTS_RESPONSE',
  AdmGetRoutesObjectsFail = '@/CM_ADM_GET_ROUTES_OBJECTS_FAIL',

  AdmGetRoutesRequest = '@/CM_ADM_GET_ROUTES_REQUEST',
  AdmGetRoutesResponse = '@/CM_ADM_GET_ROUTES_RESPONSE',
  AdmGetRoutesFail = '@/CM_ADM_GET_ROUTES_FAIL',

  CitGetRoutesRequest = '@/CM_CIT_ADM_GET_ROUTES_REQUEST',
  CitGetRoutesResponse = '@/CM_CIT_ADM_GET_ROUTES_RESPONSE',
  CitGetRoutesFail = '@/CM_CIT_ADM_GET_ROUTES_FAIL',

  AdmSetSelectedRoutesCompany = '@/CM_ADM_SET_SELECTED_ROUTES_COMPANY',

  CitAddNewRouteRequest = '@/CM_CIT_ADM_ADD_NEW_ROUTE_REQUEST',
  CitAddNewRouteResponse = '@/CM_CIT_ADM_ADD_NEW_ROUTE_RESPONSE',
  CitAddNewRouteFail = '@/CM_CIT_ADM_ADD_NEW_ROUTE_FAIL',

  CitRemoveRouteRequest = '@/CM_CIT_ADM_REMOVE_ROUTE_REQUEST',
  CitRemoveRouteResponse = '@/CM_CIT_ADM_REMOVE_ROUTE_RESPONSE',
  CitRemoveRouteFail = '@/CM_CIT_ADM_REMOVE_ROUTE_FAIL',

  CitUpdateRouteRequest = '@/CM_CIT_ADM_UPDATE_ROUTE_REQUEST',
  CitUpdateRouteResponse = '@/CM_CIT_ADM_UPDATE_ROUTE_RESPONSE',
  CitUpdateRouteFail = '@/CM_CIT_ADM_UPDATE_ROUTE_FAIL',

  AdmAddNewRouteRequest = '@/CM_ADM_ADD_NEW_ROUTE_REQUEST',
  AdmAddNewRouteResponse = '@/CM_ADM_ADD_NEW_ROUTE_RESPONSE',
  AdmAddNewRouteFail = '@/CM_ADM_ADD_NEW_ROUTE_FAIL',

  AdmRemoveRouteRequest = '@/CM_ADM_REMOVE_ROUTE_REQUEST',
  AdmRemoveRouteResponse = '@/CM_ADM_REMOVE_ROUTE_RESPONSE',
  AdmRemoveRouteFail = '@/CM_ADM_REMOVE_ROUTE_FAIL',

  AdmUpdateRouteRequest = '@/CM_ADM_UPDATE_ROUTE_REQUEST',
  AdmUpdateRouteResponse = '@/CM_ADM_UPDATE_ROUTE_RESPONSE',
  AdmUpdateRouteFail = '@/CM_ADM_UPDATE_ROUTE_FAIL',

  AdmSetChangeRouteConfirmModalOpen = '@/CM_ADM_SET_CHANGE_ROUTE_CONFIRM_MODAL_OPEN',
  AdmSetSelectedRoute = '@/CM_ADM_SET_SELECTED_ROUTE',
  AdmSetNextRoute = '@/CM_ADM_SET_NEXT_ROUTE',

  AdmSelectAllRouteObjects = '@/CM_ADM_SELECT_ALL_ROUTE_OBJECTS',

  AdmSetCheckedRouteObject = '@/CM_ADM_SET_CHECKED_ROUTE_OBJECT',

  AdmSetIsModifyRouteObjects = '@/CM_ADM_SET_IS_MODIFY_ROUTE_OBJECTS',

  // CashSorters ===========================================================

  AdmGetCashSortersRequest = '@/CM_ADM_GET_CASH_SORTERS_REQUEST',
  AdmGetCashSortersResponse = '@/CM_ADM_GET_CASH_SORTERS_RESPONSE',
  AdmGetCashSortersFail = '@/CM_ADM_GET_CASH_SORTERS_FAIL',

  AdmGetCashSorterRequest = '@/CM_ADM_GET_CASH_SORTER_REQUEST',
  AdmGetCashSorterResponse = '@/CM_ADM_GET_CASH_SORTER_RESPONSE',
  AdmGetCashSorterFail = '@/CM_ADM_GET_CASH_SORTER_FAIL',

  AdmAddCashSorterRequest = '@/CM_ADM_ADD_CASH_SORTER_REQUEST',
  AdmAddCashSorterFail = '@/CM_ADM_ADD_CASH_SORTER_FAIL',

  AdmUpdateCashSorterRequest = '@/CM_ADM_UPDATE_CASH_SORTER_REQUEST',
  AdmUpdateCashSorterFail = '@/CM_ADM_UPDATE_CASH_SORTER_FAIL',

  AdmDeleteCashSorterRequest = '@/CM_ADM_DELETE_CASH_SORTER_REQUEST',
  AdmDeleteCashSorterFail = '@/CM_ADM_DELETE_CASH_SORTER_FAIL',

  AdmSetCashSortersFilter = '@/CM_ADM_SET_CASH_SORTERS_FILTER',
  AdmSetCashSortersSort = '@/CM_ADM_SET_CASH_SORTERS_SORT',
  AdmSetSelectedCashSorter = '@/CM_ADM_SET_SELECTED_CASH_SORTER',
  AdmSetCashSorterModalType = '@/CM_ADM_SET_CASH_SORTER_MODAL_TYPE',
}

export enum CMAdminCommonActions {
  AdmSetSelectedPanel = '@/CM_ADM_SET_SELECTED_PANEL',

  AdmGetFilterObjectsRequest = '@/CM_ADM_GET_FILTER_OBJECTS_REQUEST',
  AdmGetFilterObjectsResponse = '@/CM_ADM_GET_FILTER_OBJECTS_RESPONSE',
  AdmGetFilterObjectsFail = '@/CM_ADM_GET_FILTER_OBJECTS_FAIL',

  AdmGetFilterNodesRequest = '@/ADM_GET_FILTER_NODES_REQUEST_CM',
  AdmGetFilterNodesResponse = '@/ADM_GET_FILTER_NODES_RESPONSE_CM',
}

export enum CMObjectsActions {
  // Common ==============================================
  AdmSetSelectedObjectsPanel = '@/CM_ADM_SET_SELECTED_OBJECTS_PANEL',

  // Objects =============================================
  AdmSetCalcMethodsInitial = '@/CM_ADM_SET_CALC_METHODS_INITIAL',
  AdmSetObjectsTablesInitial = '@/CM_ADM_SET_OBJECTS_TABLES_INITIAL',

  GetAdmObjectsReportsRequest = '@/CM_ADM_GET_OBJECTS_REPORTS_REQUEST',
  GetAdmObjectsReportsResponse = '@/CM_ADM_GET_OBJECTS_REPORTS_RESPONSE',
  GetAdmObjectsReportsFail = '@/CM_ADM_GET_OBJECTS_REPORTS_FAIL',

  GetAdmObjectsReportRequest = '@/CM_ADM_GET_OBJECTS_REPORT_REQUEST',
  GetAdmObjectsReportResponse = '@/CM_ADM_GET_OBJECTS_REPORT_RESPONSE',
  GetAdmObjectsReportFail = '@/CM_ADM_GET_OBJECTS_REPORT_FAIL',

  AdmSetObjectFilter = '@/CM_ADM_SET_OBJECT_FILTER',
  AdmResetObjectFilter = '@/CM_ADM_RESET_OBJECT_FILTER',

  AdmGetObjectsTableRequest = '@/CM_ADM_GET_OBJECTS_TABLE_REQUEST',
  AdmGetObjectsTableResponse = '@/CM_ADM_GET_OBJECTS_TABLE_RESPONSE',
  AdmGetObjectsTableFail = '@/CM_ADM_GET_OBJECTS_TABLE_FAIL',

  AdmSetSelectedObject = '@/CM_ADM_SET_SELECTED_OBJECT',
  AdmSetObjectsPaging = '@/CM_ADM_SET_OBJECTS_PAGING',
  AdmSortObjectsTable = '@/CM_ADM_SORT_OBJECTS_TABLE',

  AdmCreateObjectRequest = '@/CM_ADM_CREATE_OBJECT_REQUEST',
  AdmCreateObjectResponse = '@/CM_ADM_CREATE_OBJECT_RESPONSE',
  AdmCreateObjectFail = '@/CM_ADM_CREATE_OBJECT_FAIL',

  AdmUpdateObjectRequest = '@/CM_ADM_UPDATE_OBJECT_REQUEST',
  AdmUpdateObjectResponse = '@/CM_ADM_UPDATE_OBJECT_RESPONSE',
  AdmUpdateObjectFail = '@/CM_ADM_UPDATE_OBJECT_FAIL',

  AdmDeleteObjectRequest = '@/CM_ADM_DELETE_OBJECT_REQUEST',
  AdmDeleteObjectResponse = '@/CM_ADM_DELETE_OBJECT_RESPONSE',
  AdmDeleteObjectFail = '@/CM_ADM_DELETE_OBJECT_FAIL',

  AdmHandleObjectModal = '@/CM_ADM_HANDLE_OBJECT_MODAL',

  AdmSetObjFilterCashPoints = '@/CM_ADM_SET_OBJECT_FILTER_CASH_POINTS',

  AdmGetObjectsFirstSessionRequest = '@/CM_ADM_GET_OBJECTS_FIRST_SESSION_REQUEST',
  AdmGetObjectsFirstSessionResponse = '@/CM_ADM_GET_OBJECTS_FIRST_SESSION_RESPONSE',
  AdmGetObjectsFirstSessionFail = '@/CM_ADM_GET_OBJECTS_FIRST_SESSION_FAIL',

  AdmGetObjectsAccountsRequest = '@/CM_ADM_GET_OBJECTS_ACCOUNTS_REQUEST',
  AdmGetObjectsAccountsResponse = '@/CM_ADM_GET_OBJECTS_ACCOUNTS_RESPONSE',
  AdmGetObjectsAccountsFail = '@/CM_ADM_GET_OBJECTS_ACCOUNTS_FAIL',

  AdmUpdateObjectsAccountsRequest = '@/CM_ADM_UPDATE_OBJECTS_ACCOUNTS_REQUEST',
  AdmUpdateObjectsAccountsFail = '@/CM_ADM_UPDATE_OBJECTS_ACCOUNTS_FAIL',

  AdmGetObjectsCassettesConfigRequest = '@/CM_ADM_GET_OBJECTS_CASSETTES_CONFIG_REQUEST',
  AdmGetObjectsCassettesConfigResponse = '@/CM_ADM_GET_OBJECTS_CASSETTES_CONFIG_RESPONSE',
  AdmGetObjectsCassettesConfigFail = '@/CM_ADM_GET_OBJECTS_CASSETTES_CONFIG_FAIL',

  AdmGetObjectsModifyConfigRequest = '@/CM_ADM_GET_OBJECTS_MODIFY_CONFIG_REQUEST',
  AdmGetObjectsModifyConfigFail = '@/CM_ADM_GET_OBJECTS_MODIFY_CONFIG_FAIL',

  AdmGetObjectsDenominationRequest = '@/CM_ADM_GET_OBJECTS_DENOMINATION_REQUEST',
  AdmGetObjectsDenominationResponse = '@/CM_ADM_GET_OBJECTS_DENOMINATION_RESPONSE',
  AdmGetObjectsDenominationFail = '@/CM_ADM_GET_OBJECTS_DENOMINATION_FAIL',

  AdmSetObjectsDenominationRequest = '@/CM_ADM_SET_OBJECTS_DENOMINATION_REQUEST',
  AdmSetObjectsDenominationResponse = '@/CM_ADM_SET_OBJECTS_DENOMINATION_RESPONSE',
  AdmSetObjectsDenominationFail = '@/CM_ADM_SET_OBJECTS_DENOMINATION_FAIL',

  AdmGetObjectsCalcMethodRequest = '@/CM_ADM_GET_OBJECTS_CALC_METHOD_REQUEST',
  AdmGetObjectsCalcMethodResponse = '@/CM_ADM_GET_OBJECTS_CALC_METHOD_RESPONSE',
  AdmGetObjectsCalcMethodFail = '@/CM_ADM_GET_OBJECTS_CALC_METHOD_FAIL',

  AdmSetObjectsCalcMethodRequest = '@/CM_ADM_SET_OBJECTS_CALC_METHOD_REQUEST',
  AdmSetObjectsCalcMethodFail = '@/CM_ADM_SET_OBJECTS_CALC_METHOD_FAIL',

  // Vendors
  AdmGetObjectsVendorsRequest = '@/CM_ADM_GET_OBJECTS_VENDORS_REQUEST',
  AdmGetObjectsVendorsResponse = '@/CM_ADM_GET_OBJECTS_VENDORS_RESPONSE',
  AdmGetObjectsVendorsFail = '@/CM_ADM_GET_OBJECTS_VENDORS_FAIL',

  // Models
  AdmGetObjectsModelsRequest = '@/CM_ADM_GET_OBJECTS_MODELS_REQUEST',
  AdmGetObjectsModelsResponse = '@/CM_ADM_GET_OBJECTS_MODELS_RESPONSE',
  AdmGetObjectsModelsFail = '@/CM_ADM_GET_OBJECTS_MODELS_FAIL',

  // Not Registered ======================================
  AdmGetNotRegisteredRequest = '@/CM_ADM_GET_NOT_REGISTERED_REQUEST',
  AdmGetNotRegisteredResponse = '@/CM_ADM_GET_NOT_REGISTERED_RESPONSE',
  AdmGetNotRegisteredFail = '@/CM_ADM_GET_NOT_REGISTERED_FAIL',

  AdmSetNotRegisteredObject = '@/CM_ADM_SET_NOT_REGISTERED_OBJECT',
  AdmToggleNotRegisteredObject = '@/CM_ADM_TOGGLE_NOT_REGISTERED_OBJECT',
  AdmCheckAllNotRegisteredObject = '@/CM_ADM_CHECK_ALL_NOT_REGISTERED_OBJECT',

  AdmSetNotRegisteredObjectsPagingRequest = '@/CM_ADM_SET_NOT_REGISTERED_OBJECTS_PAGING_REQUEST',
  AdmSortNotRegistered = '@/CM_ADM_SORT_NOT_REGISTERED_OBJECT',

  AdmImportAdmNotRegisteredObjectsRequest = '@/CM_ADM_IMPORT_NOT_REGISTERED_OBJECTS_REQUEST',
  AdmImportAdmNotRegisteredObjectsFail = '@/CM_ADM_IMPORT_NOT_REGISTERED_OBJECTS_FAIL',

  // Limits =============================================
  AdmGetAtmLimitsRequest = '@/CM_ADM_GET_ATM_LIMITS_REQUEST',
  AdmGetAtmLimitsResponse = '@/CM_ADM_GET_ATM_LIMITS_RESPONSE',
  AdmGetAtmLimitsFail = '@/CM_ADM_GET_ATM_LIMITS_FAIL',

  AdmApplyAtmLimitsFilterRequest = '@/CM_ADM_APPLY_ATM_LIMITS_FILTER',
  AdmSetLimitsRequestData = '@/CM_ADM_SET_LIMITS_REQUEST_DATA',
  AdmSortAtmLimits = '@/CM_ADM_SORT_ATM_LIMITS',

  AdmAddAtmLimitRequest = '@/CM_ADM_ADD_ATM_LIMIT_REQUEST',
  AdmAddAtmLimitFail = '@/CM_ADM_ADD_ATM_LIMIT_FAIL',

  AdmApplyLimitsFilterRequest = '@/CM_ADM_APPLY_LIMITS_FILTER',

  AdmGetKioskLimitsRequest = '@/CM_ADM_GET_KIOSK_LIMITS_REQUEST',
  AdmGetKioskLimitsResponse = '@/CM_ADM_GET_KIOSK_LIMITS_RESPONSE',
  AdmGetKioskLimitsFail = '@/CM_ADM_GET_KIOSK_LIMITS_FAIL',

  AdmAddKioskLimitRequest = '@/CM_ADM_ADD_KIOSK_LIMIT_REQUEST',
  AdmAddKioskLimitFail = '@/CM_ADM_ADD_KIOSK_LIMIT_FAIL',

  AdmSetKioskLimitsRequestData = '@/CM_ADM_SET_KIOSK_LIMITS_REQUEST_DATA',
  AdmSortKioskLimits = '@/CM_ADM_SORT_KIOSK_LIMITS',

  AdmSetKioskAndAtmInitial = '@/CM_ADM_SET_KIOSK_AND_ATM_SORT_INITIAL',

  // Incidents: Limits ==================================
  AdmSetIncidentsLimitsFilter = '@/CM_ADM_SET_INCIDENTS_LIMITS_FILTER',

  AdmSortIncidentsLimits = '@/CM_ADM_SORT_INCIDENTS_LIMITS',
  AdmGetIncidentsLimitsRequest = '@/CM_ADM_GET_INCIDENTS_LIMITS_REQUEST',
  AdmGetIncidentsLimitsResponse = '@/CM_ADM_GET_INCIDENTS_LIMITS_RESPONSE',
  AdmGetIncidentsLimitsFail = '@/CM_ADM_GET_INCIDENTS_LIMITS_FAIL',

  AdmAddIncidentsLimitRequest = '@/CM_ADM_ADD_INCIDENTS_LIMIT_REQUEST',
  AdmAddIncidentsLimitFail = '@/CM_ADM_ADD_INCIDENTS_LIMIT_FAIL',

  // Cassette Limits ======================================
  AdmSortCassetteLimit = '@/CM_ADM_SORT_CASSETTES_LIMITS',
  AdmGetCassettesLimitsRequest = '@/CM_ADM_GET_CASSETTES_LIMITS_REQUEST',
  AdmGetCassettesLimitsResponse = '@/CM_ADM_GET_CASSETTES_LIMITS_RESPONSE',
  AdmGetCassettesLimitsFail = '@/CM_ADM_GET_CASSETTES_LIMITS_FAIL',

  AdmUpdateCassettesLimitRequest = '@/CM_ADM_UPDATE_CASSETTES_LIMIT_REQUEST',
  AdmUpdateCassettesLimitFail = '@/CM_ADM_UPDATE_CASSETTES_LIMIT_FAIL',

  // Incidents: Cassette Limits =============================
  AdmSortCassetteLimitIncident = '@/CM_SORT_CASSETTES_LIMITS_INCIDENT',

  AdmGetCassetteLimitIncidentRequest = '@/CM_ADM_GET_CASSETTES_LIMITS_INCIDENT_REQUEST',
  AdmGetCassetteLimitIncidentResponse = '@/CM_ADM_GET_CASSETTES_LIMITS_INCIDENT_RESPONSE',
  AdmGetCassetteLimitIncidentFail = '@/CM_ADM_GET_CASSETTES_LIMITS_RESPONSE_FAIL',

  AdmUpdateCassetteLimitIncidentRequest = '@/CM_ADM_UPDATE_CASSETTES_LIMIT_INCIDENT_REQUEST',
  AdmUpdateCassetteLimitIncidentFail = '@/CM_ADM_UPDATE_CASSETTES_LIMIT_INCIDENT_FAIL',

  // Special Days ========================================
  AdmGetSpecialDaysRequest = '@/CM_ADM_GET_SPECIAL_DAYS_REQUEST',
  AdmGetSpecialDaysResponse = '@/CM_ADM_GET_SPECIAL_DAYS_RESPONSE',
  AdmGetSpecialDaysFail = '@/CM_ADM_GET_SPECIAL_DAYS_FAIL',

  AdmAddSpecialDayRequest = '@/CM_ADM_ADD_SPECIAL_DAY_REQUEST',
  AdmUpdateSpecialDayRequest = '@/CM_ADM_UPDATE_SPECIAL_DAY_REQUEST',
  AdmDeleteSpecialDayRequest = '@/CM_ADM_DELETE_SPECIAL_DAY_REQUEST',

  AdmSpecialDaysOperationFail = '@/CM_ADM_SPECIAL_DAYS_OPERATION_FAIL',

  AdmSortSpecialDays = '@/CM_ADM_SORT_SPECIAL_DAYS',

  // Special Period ======================================
  AdmGetSpecialPeriodsRequest = '@/CM_ADM_GET_SPECIAL_PERIODS_REQUEST',
  AdmGetSpecialPeriodsResponse = '@/CM_ADM_GET_SPECIAL_PERIODS_RESPONSE',
  AdmGetSpecialPeriodsFail = '@/CM_ADM_GET_SPECIAL_PERIODS_FAIL',

  AdmAddSpecialPeriodRequest = '@/CM_ADM_ADD_SPECIAL_PERIOD_REQUEST',
  AdmUpdateSpecialPeriodRequest = '@/CM_ADM_UPDATE_SPECIAL_PERIOD_REQUEST',
  AdmDeleteSpecialPeriodRequest = '@/CM_ADM_DELETE_SPECIAL_PERIOD_REQUEST',

  AdmSpecialPeriodOperationFail = '@/CM_ADM_SPECIAL_PERIOD_OPERATION_FAIL',

  AdmSortSpecialPeriod = '@/CM_ADM_SORT_SPECIAL_PERIOD',

  // Networks ============================================
  AdmSetSelectedNetworkNode = '@/CM_ADM_SET_SELECTED_NETWORK_NODE',

  AdmGetNetworkNodesRequest = '@/CM_ADM_GET_NETWORK_NODES_REQUEST',
  AdmGetNetworkNodesResponse = '@/CM_ADM_GET_NETWORK_NODES_RESPONSE',
  AdmGetNetworkNodesFail = '@/CM_ADM_GET_NETWORK_NODES_FAIL',

  AdmGetNetworkNodeRequest = '@/CM_ADM_GET_NETWORK_NODE_REQUEST',
  AdmGetNetworkNodeResponse = '@/CM_ADM_GET_NETWORK_NODE_RESPONSE',
  AdmGetNetworkNodeFail = '@/CM_ADM_GET_NETWORK_NODE_FAIL',

  AdmAddNetworkNodeRequest = '@/CM_ADM_ADD_NETWORK_NODE_REQUEST',
  AdmUpdateNetworkNodeRequest = '@/CM_ADM_UPDATE_NETWORK_NODE_REQUEST',
  AdmDeleteNetworkNodeRequest = '@/CM_ADM_DELETE_NETWORK_NODE_REQUEST',

  AdmOperationWithNetworkFail = '@/CM_ADM_OPERATION_WITH_NETWORK_FAIL',

  AdmSortNetworkObjects = '@/CM_ADM_SORT_NETWORK_OBJECTS',
  AdmSetNetworkObjectsFilter = '@/CM_ADM_SET_NETWORK_OBJECTS_FILTER',
  AdmResetNetworkFilter = '@/CM_ADM_RESET_NETWORK_FILTER',

  AdmGetNetworkObjectsRequest = '@/CM_ADM_GET_NETWORK_OBJECTS_REQUEST',
  AdmGetNetworkObjectsResponse = '@/CM_ADM_GET_NETWORK_OBJECTS_RESPONSE',
  AdmGetNetworkObjectsFail = '@/CM_ADM_GET_NETWORK_OBJECTS_FAIL',

  AdmAddNetworkObjectsRequest = '@/CM_ADM_ADD_NETWORK_OBJECTS_REQUEST',
  AdmAddNetworkObjectsFail = '@/CM_ADM_ADD_NETWORK_OBJECTS_FAIL',

  AdmDeleteNetworkObjectRequest = '@/CM_ADM_DELETE_NETWORK_OBJECT_REQUEST',
  AdmDeleteNetworkObjectFail = '@/CM_ADM_DELETE_NETWORK_OBJECT_FAIL',

  AdmSetNetworkFilterCashPoints = '@/CM_ADM_SET_NETWORK_FILTER_CASH_POINTS',

  // Cash Replenishment Cost =============================
  AdmSetCashReplenishmentCostFilter = '@/CM_ADM_SET_CASH_REPLEN_COSTS_Filter',

  AdmGetCashReplenishmentCostRequest = '@/CM_ADM_GET_CASH_REPLEN_COSTS_REQUEST',
  AdmGetCashReplenishmentCostResponse = '@/CM_ADM_GET_CASH_REPLEN_COSTS_RESPONSE',
  AdmGetCashReplenishmentCostFail = '@/CM_ADM_GET_CASH_REPLEN_COSTS_FAIL',

  AdmUpdateCashReplenishmentCostRequest = '@/CM_ADM_UPDATE_CASH_REPLEN_COSTS_REQUEST',
  AdmUpdateCashReplenishmentCostFail = '@/CM_ADM_UPDATE_CASH_REPLEN_COSTS_FAIL',

  // User Networks =========================================
  AdmSortNetworksUsers = '@_CM_SORT_NETWORKS_USERS',

  AdmGetNetworksUsersRequest = '@/CM_ADM_GET_NETWORKS_USERS_REQUEST',
  AdmGetNetworksUsersResponse = '@/CM_ADM_GET_NETWORKS_USERS_RESPONSE',
  AdmGetNetworksUsersFail = '@/CM_ADM_GET_NETWORKS_USERS_FAIL',

  AdmGetUserNetworkNodesRequest = '@/CM_ADM_GET_USER_NETWORK_NODES_REQUEST',
  AdmGetUserNetworkNodesResponse = '@/CM_ADM_GET_USER_NETWORK_NODES_RESPONSE',
  AdmGetUserNetworkNodesFail = '@/CM_ADM_GET_USER_NETWORK_NODES_FAIL',

  AdmEditUserNetworkNodesRequest = '@/CM_ADM_EDIT_USER_NETWORK_NODES_REQUEST',
  AdmEditUserNetworkNodesFail = '@/CM_ADM_EDIT_USER_NETWORK_NODES_Fail',

  // Working Hours =======================================
  AdmSetSelectedWorkingHours = '@/CM_ADM_SET_SELECTED_WORKING_HOURS',
  AdmSetWorkingHoursFilter = '@/CM_ADM_SET_WORKING_HOURS_FILTER',

  AdmGetWorkingHoursRequest = '@/CM_ADM_GET_WORKING_HOURS_REQUEST',
  AdmGetWorkingHoursResponse = '@/CM_ADM_GET_WORKING_HOURS_RESPONSE',
  AdmGetWorkingHoursFail = '@/CM_ADM_GET_WORKING_HOURS_FAIL',

  AdmDeleteWorkingHoursRequest = '@/CM_ATM_DELETE_WORKING_HOURS_REQUEST',
  AdmDeleteWorkingHoursFail = '@/CM_ATM_DELETE_WORKING_HOURS_FAIL',

  AdmEditWorkingHoursRequest = '@/CM_ADM_EDIT_WORKING_HOURS_REQUEST',
  AdmEditWorkingHoursFail = '@/CM_ADM_EDIT_WORKING_HOURS_FAIL',

  AdmGetCollectionDaysRequest = '@/CM_ADM_GET_COLLECTION_DAYS_REQUEST',
  AdmGetCollectionDaysResponse = '@/CM_ADM_GET_COLLECTION_DAYS_RESPONSE',
  AdmGetCollectionDaysFail = '@/CM_ADM_GET_COLLECTION_DAYS_FAIL',

  AdmUpdateCollectionDaysRequest = '@/CM_ADM_UPDATE_COLLECTION_DAYS_REQUEST',
  AdmUpdateCollectionDaysFail = '@/CM_ADM_UPDATE_COLLECTION_DAYS_FAIL',

  AdmGetWorkingCollectionDaysRequest = '@/CM_ADM_GET_WORKING_COLLECTION_DAYS_REQUEST',
  AdmGetWorkingCollectionDaysResponse = '@/CM_ADM_GET_WORKING_COLLECTION_DAYS_RESPONSE',
  AdmGetWorkingCollectionDaysFail = '@/CM_ADM_GET_WORKING_COLLECTION_DAYS_FAIL',

  AdmUpdateWorkingCollectionDaysRequest = '@/CM_ADM_UPDATE_WORKING_COLLECTION_DAYS_REQUEST',
  AdmUpdateWorkingCollectionDaysFail = '@/CM_ADM_UPDATE_WORKING_COLLECTION_DAYS_FAIL',

  AdmSetWorkingHoursRequestData = '@/CM_ADM_SET_WORKING_HOURS_REQUEST_DATA',
}
